import React, {Component} from "react";
// import Select from 'react-select';
import DropdownComponent from "../components/dropdown";
import {changeFloat, roundup, numberWithCommas_display as numberWithCommas} from "../admin/service/excelService";
// import {NotificationManager} from 'react-notifications';
import { Toast } from "primereact/toast";
import CheckButton from "../components/checkButton";
var logging = require('../admin/service/loggingComponent.js');
const loggingHeader = '[frontend][staff][reserve_history]';

var config = require('../config.js');
const backend = config.backend;
var service = require('../admin/service/getinfo.js')
// const customStyles = service.customStyles;


class ReserveHistory extends Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.checkReserve = this.checkReserve.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.showError = this.showError.bind(this);
    // this.showSuccess = this.showSuccess.bind(this);

    this.state = {
      staff:this.props.id,
      months: [],
      years:[],
      reserve_fields:[
        'Id',
        'StaffId',
        'Month',
        'Year',
        'BalanceBF',
        'BalanceBF_rmk',
        'Donations',
        'Donations_rmk',
        'CashProject',
        'CashProject_rmk',
        'TransferIN',
        'TransferIN_rmk',
        'GovernCredit',
        'GovernCredit_rmk',
        'IncomeOthers',
        'IncomeOthers_rmk',
        'Budget',
        'Budget_rmk',
        'Insurance',
        'Insurance_rmk',
        'AdminCharges',
        'AdminCharges_rmk',
        'APLT',
        'APLT_rmk',
        'CDAC',
        'CDAC_rmk',
        'SINDA',
        'SINDA_rmk',
        'RelatedFunds',
        'RelatedFunds_rmk',
        'EmperCPF',
        'EmperCPF_rmk',
        'EmpeeCPF',
        'EmpeeCPF_rmk',
        'NetSalary',
        'NetSalary_rmk',
        'MinistryExp',
        'MinistryExp_rmk',
        'TransferOUT',
        'TransferOUT_rmk',
        'CreditCard',
        'CreditCard_rmk',
        'OutcomeOthers',
        'OutcomeOthers_rmk',
        'ReservedFundCF',
        'ReservedFundCF_rmk'
      ],
      month:'',
      year:'',
      errors:{},
      reserve:{},
      checked: false,
      getData: false,
      role:''
    }
  }




  componentDidMount() {
    this._isMounted = true;
    this.renderMonth();
    this.renderYear();
    this.renderReserveTable();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  // showSuccess(message) {
  //   this.toast.show({severity:'success', summary: 'Processing Success', detail:message, life: 3000});
  // }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Processing Failed', detail:message, life: 3000});
  } 


  // renderMonth = _ =>{
  //   const months = moment.months();
  //   console.log(months);
  //   var months_drop = [];
  //   for (var m in months){
  //     months_drop.push({
  //       'value': m,
  //       'label':months[m]
  //     });
  //   }
  //   console.log(months_drop);
  //   this.setState({months:months_drop})
  // }

  renderMonth = _ =>{

    service.getMonthList()
    .then((months_drop) => {
      if (this._isMounted){
        this.setState({months:months_drop});
      }
    })

  }

  // renderYear = _ => {
  //   var year = year_s;
  //   const year_e = moment().year();
  //   let years_drop = [];
  //
  //   while (year < year_e+1){
  //     years_drop.push({
  //       'value': year,
  //       'label':year
  //     })
  //     // years.push(year);
  //     year = year + 1;
  //   }
  //   console.log(years_drop);
  //   this.setState({years:years_drop})
  // }

  renderYear = _ => {

    service.getYearList()
    .then((years_drop) => {
      if (this._isMounted){
        this.setState({years:years_drop});
      }
    })

  }


  handleMonthChange(m) {
    // logging.logToGoogle(loggingHeader + ' choosing month using dropdown list');
    var month = parseInt(m.value, 10 )
    this.setState({ month: month.toString()});
  }

  handleYearChange = (y) => {
    // logging.logToGoogle(loggingHeader + ' choosing year using dropdown list');
    this.setState({ year: y.value});
  }


  inputValid = () => {
    let errors = {};
    let formIsValid = true;

    const {year}  =  this.state;
    const {month}  =  this.state;


    //Check To Staff Id
    if (!(isFinite(String(year)) && year !== '' )){
      // console.log(isFinite(String(year)));
      errors['year'] = 'Please choose a year!';
      formIsValid = false;
    }

    if (!(isFinite(String(month)) && month !== '' && month !== null )){
      errors['month'] = 'Please choose a month!';
      formIsValid = false;
    }

    this.setState({errors: errors});
    return formIsValid;
  }

// Called by 'CHECK' button to get data and set the state
  checkReserve(){
    // logging.logToGoogle(loggingHeader + ' clicked CHECK');
    const {year}  =  this.state;
    const {month}  =  this.state;
    const {staff}  =  this.state;

    // console.log('staff is ', staff);
    this.setState({reserve:{}});

    if (!this.inputValid()){
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!')
    }
    else{
      this.setState({checked: true});
      this.setState({getData: false})


      fetch(backend + `api/scheduler/displayReserve/` + staff + '/'+ month + '/' + year, {
        method: 'GET',
        credentials: 'include'
      })
      .then(res => res.json())
      .then((res) => {
        this.setState({getData: true})
        // console.log('return from fetch', res);
        if(res.length > 1){
          console.log('error: multiple records returned');
          this.showError('error: multiple records returned')
        }
        else if(res.hasOwnProperty('data')){
          // console.log('record found', res.data);
          this.setState({reserve: res.data});
          this.setState({role: res.data.role})
          // console.log(this.state.reserve);
        }
        else if (res.hasOwnProperty('error')){
          console.log('error found',res['error'] )
          logging.logErrorToGoogle(loggingHeader + ' error found', res['error']);
          this.showError('There is an error in processing the reserves. Please contact our IT staff for assistance.')
        }
        else{
          console.log('error', res);
          logging.logErrorToGoogle(loggingHeader + ' error found', res['error']);
          this.showError('There is an error in processing the reserves. Please contact our IT staff for assistance.')
        }

      });
    }

  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

// Clean out the 'null', '0', 'undefined'
  processReserveDate(reserve){
    var reserve_new = reserve;
    // console.log(Object.keys(reserve));

    (Object.keys(reserve)).map((k)=>{
      // console.log(k);
      // console.log(reserve[k]);
      if (k.match(/_rmk/gi)){
        // console.log('find _rmk');
        if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === 0 || reserve[k] === undefined){
          reserve_new[k] = "";
        }
      }
      else{
        if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === "" || reserve[k] === undefined){
          reserve_new[k] = 0;
        }

      }
      return 0;

    });
    // this.setState({resreve: reserve_new});
    return reserve_new;
  }

  CDAC_SINDA(reserve){

    if(reserve.hasOwnProperty('SINDA') && reserve.SINDA !== 0){
      // console.log('has SINDA')

      return (<tr>
        <td>SINDA Funds</td>
        <td style={{textAlign:"right"}}>{numberWithCommas(reserve.SINDA)}</td>
        <td>{reserve.SINDA_rmk}</td>
      </tr>)
    }

    else if (reserve.hasOwnProperty('CDAC')){
      // console.log('has CDAC')
      return (<tr>
        <td>CDAC Funds</td>
        <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CDAC)}</td>
        <td>{reserve.CDAC_rmk}</td>
      </tr>)
    }

  }


  renderReserve(reserve_o, role){
    var reserve = this.processReserveDate(reserve_o);
    var incomeTotal;
    var outcomeTotal = roundup(changeFloat(reserve.Insurance) + changeFloat(reserve.AdminCharges) + changeFloat(reserve.APLT) + changeFloat(reserve.CDAC) + changeFloat(reserve.SINDA) + changeFloat(reserve.RelatedFunds) + changeFloat(reserve.EmpeeCPF) + changeFloat(reserve.EmperCPF) + changeFloat(reserve.NetSalary) + changeFloat(reserve.MinistryExp) + changeFloat(reserve.TransferOUT) + changeFloat(reserve.CreditCard) + changeFloat(reserve.Cheque) + changeFloat(reserve.OutcomeOthers));
    var sOrd_addition;
    var sOrd_deduction = roundup(changeFloat(outcomeTotal) - changeFloat(reserve.Budget)) ;
    var reserveCF;

    role = reserve.role;
    // console.log(role);

    // if (role === 2){
    //   var total_Donation = changeFloat(reserve.Couple);
    //   incomeTotal = roundup(changeFloat(reserve.BalanceBF) + changeFloat(reserve.CashProject) + changeFloat(reserve.TransferIN) + changeFloat(reserve.GovernCredit) + changeFloat(reserve.IncomeOthers)) ;
    //
    //   var bala_donation = roundup(changeFloat(outcomeTotal) - changeFloat(incomeTotal));
    //
    //   if (bala_donation < 0){
    //     reserve.Donations = 0;
    //
    //     // donation_process = bala_donation
    //
    //     incomeTotal = roundup(incomeTotal + 0);
    //     sOrd_addition = roundup(0 - changeFloat(reserve.Budget));
    //
    //     reserve.ReservedFundCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));
    //   }
    //
    //   else{
    //     // enought donation for wife
    //     if (bala_donation < total_Donation){
    //       reserve.Donations = bala_donation;
    //
    //       // donation_process = bala_donation
    //
    //       incomeTotal = roundup(incomeTotal + bala_donation);
    //       sOrd_addition = roundup(bala_donation - changeFloat(reserve.Budget));
    //
    //       reserve.ReservedFundCF = 0;
    //       // console.log('wife new Donation', donation_process)
    //
    //
    //     }
    //     else{
    //       reserve.Donations = total_Donation;
    //
    //       // donation_process = total_Donation
    //
    //       // console.log('wife new Donation', donation_process)
    //
    //       incomeTotal = roundup(incomeTotal+ total_Donation) ;
    //       sOrd_addition = roundup(total_Donation - changeFloat(reserve.Budget));
    //
    //       reserveCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));
    //
    //       reserve.ReservedFundCF = reserveCF;
    //     }
    //   }
    //
    // }
    //
    // else{
      // console.log(role);
      // console.log(reserve.Donations);
      incomeTotal = roundup(changeFloat(reserve.BalanceBF) + changeFloat(reserve.Donations) + changeFloat(reserve.CashProject) + changeFloat(reserve.TransferIN) + changeFloat(reserve.GovernCredit) + changeFloat(reserve.IncomeOthers)) ;
      sOrd_addition = roundup(changeFloat(reserve.Donations) - changeFloat(reserve.Budget));


      reserveCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));

      reserve.ReservedFundCF = reserveCF;
    // }


    // var total_Ratio = Math.ceil((Donations / Budget)/ 100);
    var total_Ratio;
    if (reserve.Budget === 0){
      // total_Ratio = 'Budget is not defined';
      total_Ratio = '0%';
    }
    else{
      total_Ratio = (Math.round((reserve.Donations / reserve.Budget) * 10000) / 100) + '%';

    }

    return(
      <tbody>
        <tr>
          <td><label>Addition</label></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Balance B/F</td>
          <td key='BalanceBF' style={{textAlign:"right"}}>{numberWithCommas(reserve.BalanceBF)}</td>
          <td key='BalanceBF_rmk'>{reserve.BalanceBF_rmk}</td>
        </tr>
        <tr>
          <td>Donations</td>
          <td key='Donations' style={{textAlign:"right"}}>{numberWithCommas(reserve.Donations)}</td>
          {(role===2 || role===3) ?
            <td key='Donations_rmk'>(Couple's Total Donations: {numberWithCommas(reserve.Couple)}) &nbsp;&nbsp;&nbsp; {reserve.Donations_rmk}</td>
           :
            <td key='Donations_rmk'>{reserve.Donations_rmk}</td>
          }
        </tr>
        <tr>
          <td>Cash Project</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CashProject)}</td>
          <td>{reserve.CashProject_rmk}</td>
        </tr>
        <tr>
          <td>Transfer(IN)</td>
          <td style={{textAlign:"right"}} className='transfer'>
            {numberWithCommas(reserve.TransferIN)}
          </td>
          <td>
            <ul className="dropdown" dangerouslySetInnerHTML={{__html: (reserve.TransferIN_details || '')}}></ul> {reserve.TransferIN_rmk}
          </td>
        </tr>
        <tr>
          <td>Government Credit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.GovernCredit)}</td>
          <td>{reserve.GovernCredit_rmk}</td>
        </tr>
        <tr>
          <td>Others</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.IncomeOthers)}</td>
          <td>{reserve.IncomeOthers_rmk}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(incomeTotal)}</td>
          <td></td>
        </tr>
        <tr>
          <td>Budget</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Budget)}</td>
          <td>{reserve.Budget_rmk}</td>
        </tr>
        <tr>
          <td>Surplus/Deficit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(sOrd_addition)}</td>
          <td></td>
        </tr>

        <tr><td></td><td></td><td></td></tr>

        <tr>
          <td><label>Deduction</label></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Insurance</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Insurance)}</td>
          <td>{reserve.Insurance_rmk}</td>
        </tr>
        <tr>
          <td>Singapore Admin Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.AdminCharges)}</td>
          <td>{reserve.AdminCharges_rmk}</td>
        </tr>
        <tr>
          <td>APLT</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.APLT)}</td>
          <td>{reserve.APLT_rmk}</td>
        </tr>
        {this.CDAC_SINDA(reserve)}
        {/*<tr>
          <td>CDAC Funds</td>
          <td>{CDAC}</td>
          <td>{reserve.CDAC_rmk}</td>
        </tr>*/}
        <tr>
          <td>Other Funds</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.RelatedFunds)}</td>
          <td>{reserve.RelatedFunds_rmk}</td>
        </tr>
        <tr>
          <td>Employer CPF</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.EmperCPF)}</td>
          <td>{reserve.EmperCPF_rmk}</td>
        </tr>
        <tr>
          <td>Employee CPF</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.EmpeeCPF)}</td>
          <td>{reserve.EmpeeCPF_rmk}</td>
        </tr>
        <tr>
          <td>Net Salary</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.NetSalary)}</td>
          <td>{reserve.NetSalary_rmk}</td>
        </tr>
        <tr>
          <td>Ministry Expenses</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.MinistryExp)}</td>
          <td>{reserve.MinistryExp_rmk}</td>
        </tr>
        <tr>
          <td>Transfer (Out)</td>
          <td style={{textAlign:"right"}} className='transfer'>
            {numberWithCommas(reserve.TransferOUT)}
          </td>
          <td>
            <ul className="dropdown" dangerouslySetInnerHTML={{__html: (reserve.TransferOUT_details || '')}}></ul>
            {reserve.TransferOUT_rmk}
          </td>
        </tr>
        <tr>
          <td>Credit Card Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CreditCard)}</td>
          <td>{reserve.CreditCard_rmk}</td>
        </tr>
        <tr>
          <td>Cheque Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Cheque)}</td>
          <td>{reserve.Cheque_rmk}</td>
        </tr>
        <tr>
          <td>Others</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.OutcomeOthers)}</td>
          <td>{reserve.OutcomeOthers_rmk}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(outcomeTotal)}</td>
          <td></td>
        </tr>

        <tr>
          <td>Budget</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Budget)}</td>
          <td>{reserve.Budget_rmk}</td>
        </tr>


        <tr>
          <td>Surplus/Deficit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(sOrd_deduction)}</td>
          <td></td>
        </tr>

        <tr>
          <td></td><td></td><td></td>
        </tr>

        <tr>
          <td>Reserve Fund C/F</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.ReservedFundCF)}</td>
          {(role === 3)?
            <td>(Wife's Reserve: {numberWithCommas(reserve.WifeBalance)}, Couple's Total Reserve: {numberWithCommas(reserve.ReservedFundCF + reserve.WifeBalance)}) &nbsp;&nbsp;&nbsp;{reserve.ReservedFundCF_rmk}</td>
            :
            <td>{reserve.ReservedFundCF_rmk}</td>
          }
        </tr>

        <tr>
          <td>Total Collection vs Budget</td>
          <td style={{textAlign:"right"}}>{total_Ratio}</td>
          <td></td>
        </tr>
      </tbody>
    )
  }


  renderReserveTable(){
    const {reserve} = this.state;
    let checked = this.state.checked;
    let getData = this.state.getData;

    if (checked){
      if (getData === false){
        return  (<div className="only-row">Loading... </div>);

      }
      else if ( getData === true && (reserve === undefined || this.isEmpty(reserve))  ){
        return  (<div className="only-row">No Reserve Found</div>);
      } else{
        return (<div>
          <div className = "table-container">
            <table className="table table-striped table-hover ">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Remark</th>
                </tr>
              </thead>
              {this.renderReserve(reserve)}
            </table>
          </div>
        </div>);
      }
    }
    else{
      // return (<h2>Please choose month and year to check your reserve history</h2>)
      return (<h4 className="title">Please Choose Month and Year</h4>)
      // return (<h2></h2>)
    }



  }



  render() {
    const {months} = this.state;
    const {years} = this.state;
    // const {reserve} = this.state;
    // const {budgets} = this.state;
    let reserve_table = this.renderReserveTable();
    console.log(this.state.month)
    console.log(months)


    return (<div>
      <div>
      <Toast ref={(el) => this.toast = el} />
      <form className="form-horizontal">
        <fieldset>
          <div className = "buttons col-sm-4">
            <div className="btn-group ">
              {/* <Select className='react-select-container' styles={customStyles} options={months} isSearchable="false" placeholder="month" onChange={this.handleMonthChange}/> */}
              <DropdownComponent 
              options={months}
              placeholder="month"
              handleChange={this.handleMonthChange}
              value={this.state.month}/>
              <span className="error">{this.state.errors["month"]}</span>
            </div>
          </div>

          <div className = "buttons col-sm-4">
            <div className="btn-group">
              {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange}/> */}
              <DropdownComponent
              options={years}
              placeholder="year"
              handleChange={this.handleYearChange}
              value={this.state.year} />
              <span className="error">{this.state.errors["year"]}</span>
            </div>
          </div>

          <div className="col-sm-4 check-button">
             {/* <button type="button" className="check-btn btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
             <CheckButton 
              checkReserve={this.checkReserve}/>
          </div>
        </fieldset>
      </form>

    </div>
    <div className="reserve-table" id="reserve-table">
      {reserve_table}
    </div>

  </div>);
  }
}

export default ReserveHistory;
