import React, { Component } from "react";
// import Select from 'react-select';
import DropdownComponent from "../components/dropdown.js";
import moment from 'moment';
import PayrollTable from "../components/display_payroll.js";
import ExportPDF from "../components/exportPDF.js";
// import { NotificationManager, NotificationContainer } from 'react-notifications';

import { borderedCell_payroll as borderedCell } from "./service/excelService";
import { changeFloat } from "./service/excelService";
import { roundup } from "./service/excelService";
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
// import Logout from "./../logout";
import withRouter from "../components/withRouter.js";

import ReactExport from "react-data-export";
import CheckButton from "../components/checkButton.js";
import { Toast } from "primereact/toast.js";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
var config = require('../config.js');
var service = require('./service/getinfo.js');
var logging = require('./service/loggingComponent.js');
const loggingHeader = '[frontend][report_monthly]';
// const customStyles = service.customStyles;

const backend = config.backend;
const report_title = "Staff Salary & Reserves ";
const cell_font_size = '17';
const title_font_size = '20';

var ministry_col_names = ['No', 'Name', 'Basic', 'Alwncs', 'Gross', 'CPF', 'CDAC', 'SINDA', 'OtherFunds', "D'dctins", 'Net Pay', 'CPF', 'SDL', 'Total CPF', "D'brsmnt", 'Budget', 'Donations', 'Reserves', '%'];

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}


async function loopStaffs(staffs) {
  // console.log('staffs', staffs);
  //
  // var office_no = 1;
  // var ministry_no = 1;
  var admin_total = {};
  admin_total.BasicPay = 0;
  admin_total.Allowance = 0;
  admin_total.Gross = 0;
  admin_total.EmpeeCPF = 0;
  admin_total.CDAC = 0;
  admin_total.SINDA = 0;
  admin_total.RelatedFunds = 0;
  admin_total.Deductions = 0;
  admin_total.NetPay = 0;
  admin_total.EmperCPF = 0;
  admin_total.SDL = 0;
  admin_total.total_CPF = 0;
  admin_total.dbrsmt = 0;

  var ministry_total = {};
  ministry_total.BasicPay = 0;
  ministry_total.Allowance = 0;
  ministry_total.Gross = 0;
  ministry_total.EmpeeCPF = 0;
  ministry_total.CDAC = 0;
  ministry_total.SINDA = 0;
  ministry_total.RelatedFunds = 0;
  ministry_total.Deductions = 0;
  ministry_total.NetPay = 0;
  ministry_total.EmperCPF = 0;
  ministry_total.SDL = 0;
  ministry_total.total_CPF = 0;
  ministry_total.dbrsmt = 0;
  ministry_total.Budget = 0;
  ministry_total.Donations = 0;
  ministry_total.Reserve = 0;

  var office_no = 1;
  var ministry_no = 1;

  // Initial all variables
  var officeStaffs = [];
  var ministryStaffs = [];

  await asyncForEach(staffs, async (s) => {
    // console.log('staff', s);

    var row = [];

    row.push(borderedCell(s.name, true, false));
    row.push(borderedCell(s.BasicPay));
    row.push(borderedCell(s.Allowance));
    // console.log('allowance',s.Allowance );

    row.push(borderedCell(s.Gross));
    // console.log('gorss',s.Gross );
    row.push(borderedCell(s.EmpeeCPF));
    row.push(borderedCell(s.CDAC));
    row.push(borderedCell(s.SINDA));
    row.push(borderedCell(s.RelatedFunds));
    row.push(borderedCell(s.Deductions));
    row.push(borderedCell(s.NetPay));
    row.push(borderedCell(s.EmperCPF));
    row.push(borderedCell(s.SDL));
    var total_CPF = parseFloat(s.EmpeeCPF) + parseFloat(s.EmperCPF);
    row.push(borderedCell(total_CPF));
    // row.push(numberWithCommas( parseFloat(s.EmpeeCPF) + parseFloat(s.EmperCPF) ));
    var dbrsmt = parseFloat(s.Gross) + parseFloat(s.EmperCPF) + parseFloat(s.SDL);
    row.push(borderedCell(dbrsmt));

    if (s.StaffType === 'Office' || s.StaffId === 'null' || !s.StaffId) {
      // console.log('loopStaffs: admin', row);
      row.unshift(borderedCell(office_no, true));
      // officeStaffs.push(s);
      office_no = office_no + 1;
      officeStaffs.push(row);
      admin_total.BasicPay += changeFloat(s.BasicPay);
      admin_total.Allowance += changeFloat(s.Allowance);
      admin_total.Gross += changeFloat(s.Gross);
      admin_total.EmpeeCPF += changeFloat(s.EmpeeCPF);
      admin_total.CDAC += changeFloat(s.CDAC);
      admin_total.SINDA += changeFloat(s.SINDA);
      admin_total.RelatedFunds += changeFloat(s.RelatedFunds);
      admin_total.Deductions += changeFloat(s.Deductions);
      admin_total.NetPay += changeFloat(s.NetPay);
      admin_total.EmperCPF += changeFloat(s.EmperCPF);
      admin_total.SDL += changeFloat(s.SDL);
      admin_total.total_CPF += changeFloat(total_CPF);
      admin_total.dbrsmt += changeFloat(dbrsmt);
    }
    else {
      // console.log('loopStaffs: ministry', row);
      row.unshift(borderedCell(ministry_no, true));

      ministry_no = ministry_no + 1;

      row.push(borderedCell(s.Budget)); //All staff has his/her own budget

      // console.log(s.StaffId)
      // Find a wife
      if (s.StaffId.toString().length > 7) {

        // row.push(borderedCell('')); //Budget empty

        row.push(borderedCell('')); //Wife's Donations empty
        row.push(borderedCell('')); //Wife's reserve empty
        row.push(borderedCell('')); //Wife's percentage empty

        // change previous row - husbands
        var husband_index = ministryStaffs.length;
        var husband_data = ministryStaffs[husband_index - 1];

        // console.log(husband_data);
        // console.log(husband_data[husband_data.length - 3].value);



        var husband_budget = parseFloat(husband_data[husband_data.length - 4].value.replace(/,/g, ''));
        // console.log(husband_budget);
        // console.log(s.Budget);

        var couple_budget = husband_budget + parseFloat(s.Budget);
        // console.log(couple_budget);
        //
        // ministryStaffs[husband_index - 1][15] = borderedCell(couple_budget);

        var husband_donation = parseFloat(husband_data[husband_data.length - 3].value.replace(/,/g, ''));
        // console.log('husband_donation',husband_donation);
        // console.log('wife_donation', s.Donations);

        var couple_donation = husband_donation + parseFloat(s.Donations);
        // console.log(couple_donation);

        ministryStaffs[husband_index - 1][16] = borderedCell(couple_donation);


        var husband_reserve = parseFloat(husband_data[husband_data.length - 2].value.replace(/,/g, ''));
        // console.log(husband_reserve);
        // console.log(s.ReservedFundCF);

        var couple_reserve = husband_reserve + parseFloat(s.ReservedFundCF);
        // console.log(couple_reserve);

        ministryStaffs[husband_index - 1][17] = borderedCell(couple_reserve);


        var couple_ratio = roundup(couple_reserve / couple_budget) * 100;
        couple_ratio = parseFloat(couple_ratio.toFixed(2)) + '%';
        ministryStaffs[husband_index - 1][18] = borderedCell(couple_ratio, true);

      }

      // Staff is not wife
      else {
        // row.push(borderedCell(s.Budget));

        row.push(borderedCell(s.Donations));

        row.push(borderedCell(s.ReservedFundCF));

        if (s.Budget === null || s.Budget === '0' || s.Budget === 0) {
          // s.percentage = 0 + '%';
          row.push(borderedCell('0%', true));
          // row.push('0%');
        }
        else if (s.ReservedFundCF === null || s.ReservedFundCF === '0' || s.ReservedFundCF === 0) {
          row.push(borderedCell('0%', true));
        }
        else {
          var percentage = roundup(parseFloat(s.ReservedFundCF) / parseFloat(s.Budget)) * 100;
          percentage = parseFloat(percentage.toFixed(2)) + '%';
          row.push(borderedCell(percentage, true));
          // console.log('roundup', s.percentage);
        }

      }


      ministryStaffs.push(row);
      ministry_total.BasicPay += changeFloat(s.BasicPay);
      ministry_total.Allowance += changeFloat(s.Allowance);
      ministry_total.Gross += changeFloat(s.Gross);
      ministry_total.EmpeeCPF += changeFloat(s.EmpeeCPF);
      ministry_total.CDAC += changeFloat(s.CDAC);
      ministry_total.SINDA += changeFloat(s.SINDA);
      ministry_total.RelatedFunds += changeFloat(s.RelatedFunds);
      ministry_total.Deductions += changeFloat(s.Deductions);
      ministry_total.NetPay += changeFloat(s.NetPay);
      ministry_total.EmperCPF += changeFloat(s.EmperCPF);
      ministry_total.SDL += changeFloat(s.SDL);
      ministry_total.total_CPF += changeFloat(total_CPF);
      ministry_total.dbrsmt += changeFloat(dbrsmt);
      ministry_total.Budget += changeFloat(s.Budget);
      ministry_total.Donations += changeFloat(s.Donations);
      ministry_total.Reserve += changeFloat(s.ReservedFundCF);

    }

  });


  return new Promise(function (resolve, reject) {
    var admin_total_row = ['', '']
    admin_total_row.push(borderedCell(admin_total.BasicPay, false, true));
    admin_total_row.push(borderedCell(admin_total.Allowance, false, true));
    admin_total_row.push(borderedCell(admin_total.Gross, false, true));
    admin_total_row.push(borderedCell(admin_total.EmpeeCPF, false, true));
    admin_total_row.push(borderedCell(admin_total.CDAC, false, true));
    admin_total_row.push(borderedCell(admin_total.SINDA, false, true));
    admin_total_row.push(borderedCell(admin_total.RelatedFunds, false, true));
    admin_total_row.push(borderedCell(admin_total.Deductions, false, true));
    admin_total_row.push(borderedCell(admin_total.NetPay, false, true));
    admin_total_row.push(borderedCell(admin_total.EmperCPF, false, true));
    admin_total_row.push(borderedCell(admin_total.SDL, false, true));
    admin_total_row.push(borderedCell(admin_total.total_CPF, false, true));
    admin_total_row.push(borderedCell(admin_total.dbrsmt, false, true));
    officeStaffs.push(admin_total_row);

    var ministry_total_row = ['', '']
    ministry_total_row.push(borderedCell(ministry_total.BasicPay, false, true));
    ministry_total_row.push(borderedCell(ministry_total.Allowance, false, true));
    ministry_total_row.push(borderedCell(ministry_total.Gross, false, true));
    ministry_total_row.push(borderedCell(ministry_total.EmpeeCPF, false, true));
    ministry_total_row.push(borderedCell(ministry_total.CDAC, false, true));
    ministry_total_row.push(borderedCell(ministry_total.SINDA, false, true));
    ministry_total_row.push(borderedCell(ministry_total.RelatedFunds, false, true));
    ministry_total_row.push(borderedCell(ministry_total.Deductions, false, true));
    ministry_total_row.push(borderedCell(ministry_total.NetPay, false, true));
    ministry_total_row.push(borderedCell(ministry_total.EmperCPF, false, true));
    ministry_total_row.push(borderedCell(ministry_total.SDL, false, true));
    ministry_total_row.push(borderedCell(ministry_total.total_CPF, false, true));
    ministry_total_row.push(borderedCell(ministry_total.dbrsmt, false, true));
    ministry_total_row.push(borderedCell(ministry_total.Budget, false, true));
    ministry_total_row.push(borderedCell(ministry_total.Donations, false, true));
    ministry_total_row.push(borderedCell(ministry_total.Reserve, false, true));
    // console.log(ministry_total.Reserve);
    // console.log(ministry_total.Budget);
    var percentage;
    if (ministry_total.Reserve !== 0 && ministry_total.Budget !== 0) {
      percentage = roundup(parseFloat(ministry_total.Reserve) / parseFloat(ministry_total.Budget)) * 100
      percentage = parseFloat(percentage.toFixed(2)) + '%';
    }
    else {
      percentage = '0%'
    }
    ministry_total_row.push(borderedCell(percentage, true, true));

    ministryStaffs.push(ministry_total_row);

    var final_total_row = ['', ''];
    final_total_row.push(borderedCell((ministry_total.BasicPay + admin_total.BasicPay), false, true));
    final_total_row.push(borderedCell((ministry_total.Allowance + admin_total.Allowance), false, true));
    final_total_row.push(borderedCell((ministry_total.Gross + admin_total.Gross), false, true));
    final_total_row.push(borderedCell((ministry_total.EmpeeCPF + admin_total.EmpeeCPF), false, true));
    final_total_row.push(borderedCell((ministry_total.CDAC + admin_total.CDAC), false, true));
    final_total_row.push(borderedCell((ministry_total.SINDA + admin_total.SINDA), false, true));
    final_total_row.push(borderedCell((ministry_total.RelatedFunds + admin_total.RelatedFunds), false, true));
    final_total_row.push(borderedCell((ministry_total.Deductions + admin_total.Deductions), false, true));
    final_total_row.push(borderedCell((ministry_total.NetPay + admin_total.NetPay), false, true));
    final_total_row.push(borderedCell((ministry_total.EmperCPF + admin_total.EmperCPF), false, true));
    final_total_row.push(borderedCell((ministry_total.SDL + admin_total.SDL), false, true));
    final_total_row.push(borderedCell((ministry_total.total_CPF + admin_total.total_CPF), false, true));
    final_total_row.push(borderedCell((ministry_total.dbrsmt + admin_total.dbrsmt), false, true));
    final_total_row.push(borderedCell('', true, true));
    final_total_row.push(borderedCell('', true, true));
    final_total_row.push(borderedCell('', true, true));
    final_total_row.push(borderedCell('', true, true));

    ministryStaffs.push(final_total_row);

    // console.log("officeStaffs", officeStaffs);
    // console.log("ministryStaffs", ministryStaffs);

    resolve([officeStaffs, ministryStaffs]);

  });

}

function generateCol(type) {
  // var type = type;
  var ministry_col = [
    {
      value: 'No',
      width: { wch: 4 },
      style: {
        border: {
          top: { style: "medium", color: "black" },
          bottom: { style: "medium", color: "black" },
          left: { style: "medium", color: "black" },
          right: { style: "medium", color: "black" }
        },
        font: { bold: true, sz: cell_font_size },
        fill: {
          pattern: "solid", fgColor: { rgb: "D4D4D4" }
        },
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      }
    },
    {
      value: 'Name',
      width: { wch: 26 },
      style: {
        border: {
          top: { style: "medium", color: "black" },
          bottom: { style: "medium", color: "black" },
          left: { style: "medium", color: "black" },
          right: { style: "medium", color: "black" }
        },
        font: { bold: true, sz: cell_font_size },
        fill: { pattern: "solid", fgColor: { rgb: "D4D4D4" } },
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      }
    }
  ]

  if (type === 'fake') {
    ministry_col[0].title = '';
    ministry_col[1].title = '';
    ministry_col[0].style.font.sz = 1;
    ministry_col[1].style.font.sz = 1;
  }

  for (var i = 2; i < ministry_col_names.length; i++) {
    var col = {};
    if (type === 'fake') {
      col.title = '';
    }
    else {
      col.value = ministry_col_names[i];
    }
    if (ministry_col_names[i] === 'CDAC' || ministry_col_names[i] === 'SINDA' || ministry_col_names[i] === 'OtherFunds' || ministry_col_names[i] === '%') {
      col.width = { wch: 8 };
    }
    else if (ministry_col_names[i] === 'SDL' || ministry_col_names[i] === "D'dctins") {
      col.width = { wch: 11 };
    }
    else {
      col.width = { wch: 15 };
    }
    col.style = {
      border: {
        top: { style: "medium", color: "black" },
        bottom: { style: "medium", color: "black" },
        left: { style: "medium", color: "black" },
        right: { style: "medium", color: "black" }
      },
      fill: { pattern: "solid", fgColor: { rgb: "D4D4D4" } },
      font: { bold: true, sz: cell_font_size },
      alignment: {
        horizontal: 'center',
        vertical: 'bottom'
      }
    }
    // col.border = {
    //   top: { style: "medium", color: "black" }
    // }
    ministry_col.push(col);
  }

  return ministry_col;
}


class Report_Monthly extends Component {



  constructor(props) {
    super(props);
    this.state = {
      months: [],
      years: [],
      month: '',
      year: '',
      errors: {},
      checked: false,
      space: {
        columns: [],
        data: []
      },
      admin_title: {
        ySteps: -2,
        columns: [{ title: "", width: { wch: 3 } }
        ],
        data: [[
          {
            value: "Office Staff",
            style: {
              font: {
                bold: true,
                sz: cell_font_size,
              }
            }
          },
          {}, {}, {}, {},
          {
            value: "Employee",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              },
              font: {
                bold: true,
                sz: cell_font_size
              }
            }
          },
          {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          }, {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          }, {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          },
          {}, {},
          {
            value: "Employer",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              },
              font: {
                bold: true,
                sz: cell_font_size,
              }
            }
          },
          {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          }
        ]]
      },
      admin_col: {
        // ySteps: -1,
        columns: generateCol('fake').slice(0, -4),
        data: [generateCol('real').slice(0, -4)]
        // HERE!!!
      },
      ministry_title: {
        ySteps: -1,
        columns: [],
        data: [[
          {
            value: 'Field Staff',
            style: {
              font: {
                bold: true,
                sz: cell_font_size
              }
            }
          },
          {}, {}, {}, {},
          {
            value: "Employee",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              },
              font: {
                bold: true,
                sz: cell_font_size
              }
            }
          },
          {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          }, {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          }, {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          },
          {}, {},
          {
            value: "Employer",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              },
              font: {
                bold: true,
                sz: cell_font_size
              }
            }
          },
          {
            value: "",
            style: {
              border: {
                bottom: { style: "medium", color: "black" }
              }
            }
          }
        ]]
      },
      ministry_col: {
        // ySteps: -1,
        columns: generateCol('fake'),
        data: [generateCol('real')]
      },
      admin: {
        ySteps: -1,
        columns: [],
        data: []
      },
      ministry: {
        ySteps: -1,
        columns: [],
        data: []
      },
      // admin:[],
      // ministry:[],
      multiData: [],
      redirectLogout: false

    }
    this.showError = this.showError.bind(this);
    this.showWarn = this.showWarn.bind(this);
  }
  
  _isMounted = false;



  componentDidMount() {
    this._isMounted = true;
    this.renderMonth();
    this.renderYear();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Data Failed', detail:message, life: 3000});
  } 

  showWarn(message) {
    this.toast.show({severity:'warn', summary: 'Data Warning', detail:message, life: 3000});
  } 

  // renderMonth = _ =>{
  //   const months = moment.months();
  //   // console.log(months);
  //   var months_drop = [];
  //   for (var m in months){
  //     months_drop.push({
  //       'value': m,
  //       'label':months[m]
  //     });
  //   }
  //   // console.log(months_drop);
  //   this.setState({months:months_drop})
  // }

  renderMonth = _ => {
    service.getMonthList()
      .then((months_drop) => {
        // this.setState({years:years_drop});
        if (this._isMounted) {
          this.setState({ months: months_drop });
        }
      })
  }

  // renderYear = _ => {
  //   var year = year_s;
  //   const year_e = moment().year();
  //   let years_drop = [];
  //
  //   while (year < year_e+1){
  //     years_drop.push({
  //       'value': year,
  //       'label':year
  //     })
  //     // years.push(year);
  //     year = year + 1;
  //   }
  //   // console.log(years_drop);
  //   this.setState({years:years_drop})
  // }

  renderYear = _ => {
    service.getYearList()
      .then((years_drop) => {
        // this.setState({years:years_drop});
        if (this._isMounted) {
          this.setState({ years: years_drop });
        }
      })
  }

  handleMonthChange = (m) => {
    // logging.logToGoogle(loggingHeader + ' choosing month using dropdown list');
    var month = parseInt(m.value, 10)
    // console.log(month);
    this.setState({ month: month.toString() });
    // console.log(this.state.month);
  }

  handleYearChange = (y) => {
    // logging.logToGoogle(loggingHeader + ' choosing year using dropdown list');
    this.setState({ year: y.value });
    // console.log(y.value);
    // console.log(this.state.y);
  }

  inputValid = () => {
    let errors = {};
    let formIsValid = true;

    const { year } = this.state;
    const { month } = this.state;
    // const {staff}  =  this.state;

    // console.log('year', year);
    // console.log('month', month);
    // console.log('staff', staff);


    //Check To Staff Id
    if (!(isFinite(String(year)) && year !== '')) {
      console.log(isFinite(String(year)));
      errors['year'] = 'Please choose a year!';
      formIsValid = false;
    }

    if (!(isFinite(String(month)) && month !== '' && month !== null)) {
      errors['month'] = 'Please choose a month!';
      formIsValid = false;
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  generateTitle() {
    const { month } = this.state;
    const { year } = this.state;
    const months = moment.monthsShort();


    var title = {
      ySteps: -1,
      xSteps: 8,
      columns: [],
      data: [
        [
          {
            value: report_title + months[month - 1] + ' ' + year,
            style: {
              font: {
                sz: title_font_size,
                bold: true
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                readingOrder: 4
              }
            }
          }
        ]
      ]
    }

    return title;
  }

  checkReserve = _ => {
    // logging.logToGoogle(loggingHeader + ' clicked CHECK');
    const { year } = this.state;
    const { month } = this.state;
    const { space } = this.state;
    const { admin_title } = this.state;
    const { ministry_title } = this.state;
    const { admin_col } = this.state;
    const { ministry_col } = this.state;

    // generateTitle
    var title = this.generateTitle();


    if (!this.inputValid()) {
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!')
    }
    else {
      this.setState({ checked: true });

      fetch(backend + `api/report/payroll/` + month + `/` + year, {
        method: 'GET',
        credentials: "include"
      })
        .then(res => res.json())
        .then((res) => {
          // console.log('res', res);

          if (res.redirect) {
            sessionStorage.setItem('int', 'logout');
            this.setState({ redirectLogout: true })
            window.location.reload(false);
          }

          if (res.error === 'No record found') {
            logging.logErrorToGoogle(loggingHeader + ' No record found');
            this.setState({ multiData: "Empty" });
            this.showWarn('No record found');
            return 0;
          }

          var admin_total = {};
          admin_total.BasicPay = 0;
          admin_total.Allowance = 0;
          admin_total.Gross = 0;
          admin_total.EmpeeCPF = 0;
          admin_total.CDAC = 0;
          admin_total.SINDA = 0;
          admin_total.RelatedFunds = 0;
          admin_total.Deductions = 0;
          admin_total.NetPay = 0;
          admin_total.EmperCPF = 0;
          admin_total.SDL = 0;
          admin_total.total_CPF = 0;
          admin_total.dbrsmt = 0;

          var ministry_total = {};
          ministry_total.BasicPay = 0;
          ministry_total.Allowance = 0;
          ministry_total.Gross = 0;
          ministry_total.EmpeeCPF = 0;
          ministry_total.CDAC = 0;
          ministry_total.SINDA = 0;
          ministry_total.RelatedFunds = 0;
          ministry_total.Deductions = 0;
          ministry_total.NetPay = 0;
          ministry_total.EmperCPF = 0;
          ministry_total.SDL = 0;
          ministry_total.total_CPF = 0;
          ministry_total.dbrsmt = 0;
          ministry_total.Budget = 0;
          ministry_total.Donations = 0;
          ministry_total.Reserve = 0;


          var wives = [];
          var husbands = [];

          res.sort(function (a, b) {
            // console.log(a)
            if (a.StaffType === 'field' && a.StaffId.toString().length > 7) {
              if (wives.indexOf(a) === -1) { //wife id has not add into wives
                wives.push(a);
              }
            }

            if (a.name < b.name) {
              return -1;
            }

            if (a.name > b.name) {
              return 1;
            }

            return 0;

          });

          // console.log('wives', wives);


          for (var i = 0; i < wives.length; i++) {

            // remove wife from res
            var wife_index = res.map(function (e) {
              if (e.StaffId) { return e.StaffId.toString(); }
              else { return 'error'; }
            }).indexOf(wives[i].StaffId.toString());
            // console.log('wife_index',wife_index);
            res.splice(wife_index, 1); // replace the first


            var husband_id = wives[i].StaffId.toString().slice(0, -3);
            // console.log('husband_id',husband_id);
            var husband_index = res.map(function (e) {
              if (e.StaffId) { return e.StaffId.toString(); }
              else { return 'error'; }
            }).indexOf(husband_id);
            // console.log('husband_index',husband_index);

            if (husbands.indexOf(res[husband_index]) === -1) {
              husbands.push(res[husband_index]);
            }

            if (husband_index > -1) {
              // insert wife into the right position of array
              res.splice(husband_index + 1, 0, wives[i]);

            }

            // console.log(res);

          }


          loopStaffs(res)
            .then((result) => {
              // console.log('result', result);
              this.setState({ admin: { ...this.state.admin, data: result[0] } });
              this.setState({ ministry: { ...this.state.ministry, data: result[1] } });
              this.setState({ multiData: [title, space, admin_title, admin_col, this.state.admin, space, ministry_title, ministry_col, this.state.ministry] });

            });

        })
        .catch((err) => { console.log(err) });
    }
  }


  renderReport() {
    // const {office} = this.state;
    // const {ministry} = this.state;
    // const {title} = this.state;
    const months = moment.monthsShort();
    const { month } = this.state;
    const { year } = this.state;
    const { multiData } = this.state;

    // console.log('multiData', multiData);

    const filename = "MonthlyPayroll of " + months[month - 1] + ' ' + year;

    let checked = this.state.checked;

    if (checked) {
      if (multiData.length !== 0 && multiData.length !== undefined) {
        if (multiData === 'Empty') {
          return (<div className="row">
            <h4 className="not-found-warning">No record found. <br />Please import the Monthly Payroll Detail Report first. </h4>
          </div>)
        }
        else {
          return (<div className="row">
            <div className="download-report col-sm-12">

              <ExcelFile element={<button type="button" className="btn btn-warning col-sm-2 button-excel">Download Excel</button>} filename={filename}>
                <ExcelSheet dataSet={multiData} name="MonthlyPayroll" />
              </ExcelFile>

              <ExportPDF data={multiData} type='MonthlyPayroll' />

            </div>

            <div className="row">

              <PayrollTable dataSet={multiData} type='MonthlyPayroll' />

            </div>

          </div>);
        }
      }
      else {
        // console.log('multiData is empty');
        return <h2>Loading...</h2>
      }
    }
  }

  render() {

    const { redirectLogout } = this.state;

    if (redirectLogout) {
      return (
        <BrowserRouter>
          <Switch>
            {/* <Route exact path="/logout" component={Logout} />
            <Redirect to="/logout" /> */}
            <Route path={this.props.location.pathname} render={() => <Redirect to="/logout" />} />
          </Switch>
        </BrowserRouter>
      )
    }

    // const {transfers} = this.state.transfers;
    // const {transfers} = this.state;
    const { years } = this.state;
    // const {staffs} = this.state;
    const { months } = this.state;
    let report_table = this.renderReport();


    return (<div className="yearly-report">
      <Toast ref={(el) => this.toast = el} />
      <h4 className="title">Generate Monthly Payroll Detail Report</h4>
      <div className="form-set row">
        <form className="form-horizontal">
          <div className="form-sets col-sm-12">
            <fieldset>
              <div className="fieldset">

                <div className="buttons col-sm-4">
                  <div className="btn-group">
                    {/* <Select className='react-select-container' styles={customStyles} options={months} isSearchable="false" placeholder="months" onChange={this.handleMonthChange} /> */}
                    <DropdownComponent
                    options={months}
                    placeholder="month"
                    handleChange={this.handleMonthChange}
                    value={this.state.month} />
                    <span className="error">{this.state.errors["month"]}</span>
                  </div>
                </div>

                <div className="buttons col-sm-4">
                  <div className="btn-group">
                    {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange} /> */}
                    <DropdownComponent
                    options={years}
                    placeholder="year"
                    handleChange={this.handleYearChange}
                    value={this.state.year} />
                    <span className="error">{this.state.errors["year"]}</span>
                  </div>
                </div>

                <div className="col-sm-4 check-button">
                  {/* <button type="button" className="btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
                  <CheckButton 
                  checkReserve={this.checkReserve}/>
                </div>

              </div>
            </fieldset>
          </div>
        </form>
      </div>
      <div className="report-table" id="reserve-table">
        {report_table}
      </div>

      {/* <NotificationContainer /> */}

    </div>);

  }
}

export default withRouter(Report_Monthly);
