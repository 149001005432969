import React, {Component} from "react";
import axios from 'axios'
// import {NotificationManager} from 'react-notifications';
import { Toast } from "primereact/toast";

var logging = require('./service/loggingComponent.js');
const loggingHeader = '[frontend][import_payroll]';

var config = require('../config.js');
const backend = config.backend + `api/payroll/upload`;


class Import_Payroll extends Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      selectedFile:null,
      loaded: 0
      // count:0
    }
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }


  componentDidMount(){
    this._isMounted = true;
    // this.loadData()
    // this.interval = setInterval(() => this.loadData(), 2000);


  }

  componentWillUnmount() {
    this._isMounted = false;
    // clearInterval(this.interval);
  }

  showSuccess(message, summary) {
    this.toast.show({severity:'success', summary: summary, detail:message, life: 3000});
  }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Import Failed', detail:message, life: 3000});
  } 

  showInfo(message) {
    this.toast.show({severity:'info', summary: 'Import Info', detail:message, life:7000});
  }


  handleUpload = () => {
    // logging.logToGoogle(loggingHeader + ' clicked upload');
    if (this.state.selectedFile === null){
      // NotificationManager.error('Please choose a file to upload');
      this.showError('Please choose a file to upload')

    }
    else{
      // console.log(this.state.selectedFile);
      const data = new FormData();
      data.append('file', this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(backend, data, {
          withCredentials: true,
          onUploadProgress: ProgressEvent => {

          },
        })
        .then(res => {
          if (res.data.error === 'Incorrect File'){
            // NotificationManager.error('Incorrect file detected, Please check again!');
            this.showError('Incorrect file detected, Please check again!')

          }
          else{
            // window.confirm('File uploaded successfully!');
            // NotificationManager.success('File uploaded successfully!');
            // NotificationManager.info('Updating Reserves. It may take a few minutes for the reserves to be updated.', '', 7000);
            this.showSuccess('File uploaded successfully!', 'Import Success')
            this.showInfo('Updating Reserves. It may take a few minutes for the reserves to be updated.')
            fetch(config.backend + 'api/scheduler/night', {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          })
            .then(res => {
              // console.log(res);
              // alert('Auto Update Finished! :) ');

              // NotificationManager.success('Auto update finished!');
              this.showSuccess('Auto update finished!', 'Update Success')
              // Notification.createNotification('success', 'Auto update finished!')

            });
            // window.location.reload();
            // this.loadData()
          }
          // console.log('res',res);
          // console.log('res',res.statusText);
        })
    }

  }

  handleselectedFile = event => {
    // console.log('file is ',event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }


  render() {
    // var count = this.state.count;
    // console.log(count)
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <h2>Import Monthly Payroll Detail Report</h2>

        <div className = 'upload-container row'>

          <div className="form-group">
            <div className="input-group">

              <div className = 'upload-note'>
                <h4>Please download 'Monthly Payroll Detail Report (Total Additions)' in</h4>
                <h5><b>Microsoft Excel (97-2009) Data-Only</b> format at Justlogin</h5>
              </div>
              {/*}<div className = 'upload-note'>{(count > 0) ? (<h4> {count} messages left to be processed </h4>) : (<h4 hidden>All message processed. </h4>)}</div>*/}

                <div className="custom-file ">
                  <input type="file" className="custom-file-input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="inputGroupFile" onChange={this.handleselectedFile}/>
                </div>

                <div className="input-group-append">
                  <button className="input-group-text" id="" onClick={this.handleUpload}>Upload</button>
                </div>



            </div>
          </div>

      </div>

    </div>);
  }
}

export default Import_Payroll;
