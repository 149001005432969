import React, {Component} from "react";
// import Select from 'react-select';
import DropdownComponent from "../components/dropdown";
// import {NotificationManager} from 'react-notifications';
import {numberWithCommas_display as numberWithCommas} from "./service/excelService";

import {sortDic} from "./service/tableProcess";
import {fetch_retry} from "./service/getinfo";
import CheckButton from "../components/checkButton";
import ProcessReserveTable from "../components/process_reserve_table";
import { Toast } from "primereact/toast";

var config = require('../config.js');
const backend = config.backend;

var service = require('./service/getinfo.js')
var logging = require('./service/loggingComponent.js');
const loggingHeader = '[frontend][process_reserve_insurance]';
// const customStyles = service.customStyles;

class Govern_Credit extends Component {

  _isMounted = false;


  constructor(props){
    super(props);
    this.state = {

      month:'',
      year:'',
      months: [],
      staffs: [],
      years:[],
      errors:{},
      checked: 'false',
      governList:{},
      governList_new:{},
      focusRow:''
    };
    this.setFocusRow = this.setFocusRow.bind(this);
    this.moveCursorToEnd = this.moveCursorToEnd.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }


  componentDidMount(){
    this._isMounted = true;
    this.getAllStaff();
    this.renderMonth();
    this.renderYear();
    this.renderGovernTable();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  showSuccess = (message) => {
    this.toast.show({severity:'success', summary: 'Save Success', detail:message, life: 3000});
  }

  showError = (message) => {
    this.toast.show({severity:'error', summary: 'Save Failed', detail:message, life: 3000});
  } 

  handleData = (data) => {
    this.setState({data});
  }

  handleFocusRow = (value) => {
    this.setState({focusRow: value})
  }

  // handleClick = (e) => {
  //   if (!this.node.contains(e.target)){
  //     console.log('click outside', e.target, this.state.focusRow);
  //     console.log(e.target.tagName, e.target.type);
  //     // If click outside, reset the focus
  //     if (e !== undefined && e.target!== undefined && e.target.type !== 'textarea' && e.target.tagName !== 'TR'){ // if not clicking textarea, roundup the value and reset focusRow id
  //       if (this.state.focusRow){
  //       // if (focusRow){
  //         // console.log('reset focusRow, can format the value:', this.state.governList_new[this.state.focusRow].val)
  //         const data = {...this.state.governList_new};
  //         data[this.state.focusRow].val = removeCommas(data[this.state.focusRow].val);
  //         this.setState({data});
  //         this.inputValid();
  //         // console.log(this.state.governList_new)
  //       }

  //       this.setState({focusRow:''})
  //       // focusRow = ''
  //     }
  //     return;
  //   }
  // }

  getAllStaff = _ => {

    service.getStaffList()
    .then((staffs) => {
      if (this._isMounted){
        this.setState({staffs: staffs});
      }
    })

  }

  renderMonth = _ =>{

    service.getMonthList()
    .then((months_drop) => {
      if (this._isMounted){
        this.setState({months:months_drop});
      }
    })

  }


  renderYear = _ => {

    service.getYearList()
    .then((years_drop) => {
      if (this._isMounted){
        this.setState({years:years_drop});
      }
    })

  }


  handleMonthChange = (m) => {

    var month = parseInt(m.value, 10 )
    // logging.logToGoogle(loggingHeader + ' choosing month from dropdown list')
    this.setState({ month: month.toString()});

  }

  handleYearChange = (y) => {

    // logging.logToGoogle(loggingHeader + ' choosing year from dropdown list')
    this.setState({ year: y.value});

  }


  inputValid = () => {
    let errors = {};
    let formIsValid = true;

    const {year}  =  this.state;
    const {month}  =  this.state;

    // console.log('year', year);
    // console.log('month', month);


      //Check To Staff Id
      if (!(isFinite(String(year)) && year !== '' )){
        // console.log(isFinite(String(year)));
        errors['year'] = 'Please choose a year!';
        formIsValid = false;
      }

      if (!(isFinite(String(month)) && month !== '' && month !== null )){
        errors['month'] = 'Please choose a month!';
        formIsValid = false;
      }

      // Check newGoverList
      const {governList_new} = this.state;
      for (var id in governList_new){
        if (isNaN(governList_new[id].val)){
          errors[id] = 'Please enter a number';
          formIsValid = false
        }
      }

    this.setState({errors: errors});
    return formIsValid;
  }


  checkReserve = _ => {
    // logging.logToGoogle(loggingHeader + ' clicked CHECK')
    const {year}  =  this.state;
    const {month}  =  this.state;
    const {staffs} = this.state;
    this.setState ({focusRow:''});


    if (!this.inputValid()){
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!')
    }
    else{

      // Reset check and error when check new record
      this.setState({checked: 'process', errors:''});
      fetch_retry(backend + `api/reserve/allstaffonemonth/` + month + '/' + year + '/0', {
        method: 'GET',
        credentials: "include"
      }, 2)
      .then(res => res.json())
      .then((res) => {


        if (res.hasOwnProperty('error')){
          console.log('error found', res['error']);
          this.setState({checked: 'error'});
        }
        else{
          // console.log('goverList before', res)

          res = sortDic(res.data, staffs)
          // console.log('goverList after', res)

          this.setState({governList: res});
          var data = res.data;
          var data_new = {};
          for (var i in data){
            var info = data[i];
            // console.log(info);

            var id = Object.keys(info);

            for (var key in info){
              // console.log(info[key] == null);
              // console.log(id[0], info[key]);


              if (info[key] == null){
                // data_new[id[0]] = 0;
                data_new[id[0]] = {
                  val: 0,
                  rmk: ''
                }

              }
              else{

                data_new[id[0]] = {};
                data_new[id[0]].rmk = (info[key].GovernCredit_rmk === '0' || info[key].GovernCredit_rmk === 0) ? '' : info[key].GovernCredit_rmk;
                data_new[id[0]].val = (info[key].GovernCredit == null) ? 0 : info[key].GovernCredit

                // var temp = info[key].GovernCredit_rmk;
                // console.log(temp)
              }

            }
          }

          // console.log(data_new)
          this.setState({governList_new: data_new});
          this.setState({checked: 'true'});
        }


      })
      .catch((error) => {
        logging.logErrorToGoogle(loggingHeader + ' error: ' + error)
        console.log(error)
      });

    }

  }


  setFocusRow = (e) => {
    var id = e.target.id;
    this.setState({ focusRow: id});
  }

  moveCursorToEnd = (e) => {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }


  renderCell = (row) =>{

    // console.log('renderCell', row)

    var {staffs} = this.state;
    var name = staffs[row[0]];
    var {governList_new} = this.state;
    var {focusRow} = this.state;

    // console.log(governList_new)

    var staffid = row[0];
    // console.log('focusRow', focusRow);
    return (

    <tr key = {row[0]}>

      <td>{row[0]}</td>
      <td>{name}</td>

      {

        (focusRow !== row[0]) ?
        <td key = {row[0]} > {/*// click outside of the input field*/}
          <textarea rows="1" wrap="hard" id = {row[0]} value= {numberWithCommas(governList_new[row[0]].val)} onFocus={this.setFocusRow} onChange={e=>{
            const data = {...this.state.governList_new};
            data[row[0]].val = e.target.value;
            this.setState({data});
            // console.log(this.state.governList_new)
           }}> </textarea>
           <span className="error">{this.state.errors[staffid]}</span>
         </td>
         :
         <td key = {row[0]} >
           <textarea autoFocus rows="1" wrap="hard" id = {row[0]} value= {governList_new[row[0]].val} onFocus={this.moveCursorToEnd} onChange={e=>{
             const data = {...this.state.governList_new};
             data[row[0]].val = e.target.value;
             this.setState({data});
             // console.log(this.state.governList_new)
            }}> </textarea>
            <span className="error">{this.state.errors[staffid]}</span>
          </td>

      }


       <td><textarea rows="2" wrap="hard" value= {governList_new[row[0]].rmk} onChange={e=> {
         const data = {...this.state.governList_new};
         data[row[0]].rmk = e.target.value;
         this.setState({data});
        }}> </textarea></td>

    </tr>

    )

  }


  renderRow = (row) => {

    // console.log(row);
    return Object.entries(row)
    .map(this.renderCell);

  }


  renderList = (list) => {
    return list.map(this.renderRow)
  }


  renderGovern = (governList) => {

    return (
      <tbody>

        {this.renderList(governList)}

      </tbody>
    )

  }

  renderGovernTable = _ => {
    var {governList} = this.state;
    let checked = this.state.checked;
    // console.log(governList);

    if (checked === 'true'){

      return (
      <ProcessReserveTable 
      // handleClick={this.handleClick}
      handleSubmit={this.handleSubmit}
      renderMethod={this.renderGovern}
      data={governList.data}
      columns={['Staff Id', 'Staff Name', 'Amount', 'Remark']}
      focusRow={this.state.focusRow}
      list_new={this.state.governList_new}
      handleData={this.handleData}
      inputValid={this.inputValid}
      handleFocusRow={this.handleFocusRow}/>
        // <div className = "container" onClick = {this.handleClick}>

        //   <div className="save-button">
        //      <button type="button" className="btn btn-primary submit process-reserve" onClick={this.handleSubmit}>SAVE</button>
        //   </div>

        //   <div className = "table-container reserve-table react-bootstrap-table">
        //     <form>
        //         <table className="table">
        //           <thead>
        //             <tr>
        //               <th ref={node => this.node = node}>Staff Id</th>
        //               <th>Staff Name</th>
        //               <th>Amount</th>
        //               <th>Remark</th>
        //             </tr>
        //           </thead>
        //           {this.renderGovern(governList.data)}
        //         </table>
        //     </form>

        //   </div>

        //   <div className="save-button">
        //      <button type="button" className="btn btn-primary submit process-reserve" onClick={this.handleSubmit}>SAVE</button>
        //   </div>


        // </div>
      )
    }
    else if (checked === 'process'){
      return (<h2>Loading...</h2>)
    }
    else if (checked === 'error'){
      return (<h2>Error Returned</h2>)
    }


  }


  handleSubmit = _ => {
    // logging.logToGoogle(loggingHeader + ' clicked save in process reserve insurance');
    const {governList_new} = this.state;
    var {month} = this.state;
    const {year} = this.state;


    if (!this.inputValid()){
      // NotificationManager.error('Unable to save! Please check your input! ');
      this.showError('Unable to save! Please check your input! ');
      
    }

    else{

      if (month.length === 1){
        month = '0' + month;
      }

      // console.log(governList_new)
      fetch_retry(backend + `api/reserve/updateOneField/GovernCredit/` + month + `/` + year,{
        method: 'PUT',
        credentials: "include",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
           data: governList_new
        })
      }, 2)
      .then(response => {
        // console.log(response);
        if (response.status === 200){
          // NotificationManager.success('Updated succesfully!');
          this.showSuccess('Updated succesfully!')
        }
      });

    }

  }



  render() {

    const {months} = this.state;
    const {years} = this.state;

    let govern_table = this.renderGovernTable();

    return(
      <div>
        <Toast ref={(el) => this.toast = el} />
        <h3 className = 'title'>All Staff's government credit</h3>

        <div>
          <form className="form-horizontal">
            <fieldset>
              <div className = "buttons col-sm-4">
                <div className="btn-group">
                  {/* <Select className='react-select-container' styles={customStyles} options={months} isSearchable="false" placeholder="month" onChange={this.handleMonthChange}/> */}
                  <DropdownComponent
                  options={months}
                  placeholder="month"
                  handleChange={this.handleMonthChange}
                  value={this.state.month} />
                  <span className="error">{this.state.errors["month"]}</span>
                </div>
              </div>

              <div className = "buttons col-sm-4">
                <div className="btn-group">
                  {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange}/> */}
                  <DropdownComponent
                  options={years}
                  placeholder="year"
                  handleChange={this.handleYearChange}
                  value={this.state.year} />
                  <span className="error">{this.state.errors["year"]}</span>
                </div>
              </div>

              <div className="col-sm-4 check-button">
                <CheckButton 
                checkReserve={this.checkReserve}/>
                 {/* <button type="button" className="btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
              </div>

            </fieldset>
          </form>
        </div>


        <div>
          <div>
            {govern_table}
          </div>
        </div>

      </div>
    )

  }

}


export default Govern_Credit;
