import React, {Component} from "react";
import moment from 'moment';
// import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import {fetch_retry} from "./service/getinfo";
// import { NotificationManager} from 'react-notifications';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { Button } from 'primereact/button';
import TransferTable from "../components/transferTable";
import { Toast } from "primereact/toast";
var logging = require('./service/loggingComponent.js');
const loggingHeader = '[frontend][transfer_all_pending]';

var config = require('../config.js');
const backend = config.backend;


class Transfer_All_Pending extends Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      transfers: [],
      staffs:{},
      check: false
    }
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this.deleteTransferFromList = this.deleteTransferFromList.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
    this.getPendingTransfers();
  }


  componentWillUnmount() {
    this._isMounted = false;
  }

  showSuccess(message) {
    this.toast.show({severity:'success', summary: 'Transfer Success', detail:message, life: 3000});
  }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Transfer Failed', detail:message, life: 5000});
  } 

  filterDate(value, filter) {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
        return true;
    }

    if (value === undefined || value === null) {
        return false;
    }

    return value === this.formatDate(filter);
}

  formatDate(value, title) {
    // console.log(value)
    // return moment(value).format('DD-MMM-YYYY');
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {moment(value).format('DD-MMM-YYYY')}
      </React.Fragment>
  );
  }

  formatCurrency(value, title) {
    // console.log(value)
    // return '$ '+value;
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {'$ '+value}
      </React.Fragment>
  );
  }

  noFormatColumn(value, title) {
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {value}
      </React.Fragment>
  );
  }

  formatStatus(status, title) {
    // console.log(status)
    let finalStatus;
    if (status === undefined || status === 'null' || status === null)
    {
      finalStatus =  status;
    }
    else if (status === 'approve')
    {
      finalStatus =  status.charAt(0).toUpperCase() + status.substr(1) + 'd';
    }
    else if (status === 'reject')
    {
      finalStatus =  status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
    }
    else{
      finalStatus =  status.charAt(0).toUpperCase() + status.substr(1);
    }
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {finalStatus}
      </React.Fragment>
  );
  }


  sortIcon (column, index, { sortElement, filterElement }, length){
    if (length <= 1){
      return (
        <div> {column.text} </div>
      )
    }
    else{
      var {icon} = <i className="material-icons">unfold_more</i>;

      var { order } = sortElement.props;
      if (order === 'desc'){
        icon = <i className="material-icons">keyboard_arrow_up</i>
      }
      else if (order === 'asc'){
        icon = <i className="material-icons">keyboard_arrow_down</i>
      }
      else{
        icon = <i className="material-icons">unfold_more</i>
      }

      return (
        <div>
          {icon} {column.text}
        </div>
      );
    }
  }

  deleteTransferFromList (id){
    return this.state.transfers.filter(transfers => 
    transfers["Id"]!==id
    );
  }

  onClickButton(id, type, value){
   // this can be in a nice box
    // logging.logToGoogle(loggingHeader + ' clicked ' + value);
    var answer = window.confirm("Are you sure you want to " + value + " transfer ?")

    if ( answer ) {

      fetch( backend + `api/transfer/update/` + id,{
        method:'PUT',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
               [type]:value
            })
      })
      .then(response => response.json())
      .then((response) => {
        if (response.Error !== undefined){
          // NotificationManager.error(response.Error);
          this.showError(response.Error)
        }
        else{
          // console.log(type + " of " + id +' changed to ' + value + ' successfully');
          // NotificationManager.success( 'Transfer is processed successfully');
          this.showSuccess('Transfer is processed successfully')

          // Delete from the list
          if (type === "status"){
              // this.setState(state=>{
              //   this.state.transfers = this.deleteTransferFromList(id);
              //   return this.state;
              // });

            this.setState({transfers: this.deleteTransferFromList(id)})
          }
            else if(type === "comment"){
              // console.log('refresh this row');
            }
        }

       })
      .catch(err => {
        logging.logErrorToGoogle(loggingHeader + ' error: ' + err);
        this.showError("Transfer is unable to process. Please contact our IT staff for assistance.")
      });
    } else {
        // Do nothing!
    }

  }

  buttonFormatter(cell, row, title){
    return (
      <React.Fragment>
        <span className="p-column-title">{title}</span>
          <div id="action-buttons">
            <button
                className="btn btn-success action-btn approve-btn"
                type="button"
                onClick={() =>
                this.onClickButton(cell, row.Id,"status","approve")}
            >
            {/*} <i className="material-icons">check</i>*/}
            Approve
            </button>

            <button
                className="btn btn-danger action-btn reject-btn"
                type="button"
                onClick={() =>
                this.onClickButton(cell, row.Id,"status","reject")}
            >
            {/*}<i className="material-icons">close</i>*/}
            Reject
            </button>
          </div>
      </React.Fragment>
    )
  }


  getPendingTransfers = _ => {

    fetch_retry(backend + `api/transfer/allpending`, {
      method: 'GET',
      credentials: 'include'
    }, 3)
    .then(response => response.json())
    .then((response) => {

      // Get all the staffs
      fetch_retry(backend + `api/staffsimple`, {
        method: 'GET',
        credentials: 'include'
      }, 3)
      // fetch(backend + `api/staffsimple`)
      .then(res => res.json())
      .then(({data}) => {
        // console.log('data',data);
        // console.log('response',response);

        var arr = [];
        var staffs_ = {};
        for (var s in data){

            staffs_[ data[s]['label']] = data[s]['label'];

        }

        if (this._isMounted){
          this.setState({staffs: staffs_});
        }
        // console.log(typeof this.state.staffs);
        for (var key in response){


            console.log(key, response[key]);

            if(response[key]['Comment'] === 'null'){
              delete response[key]['Comment'];
            }



            for (var k in data){

              if ( (typeof(response[key]['ToStaffId']) === "number" && response[key]['ToStaffId'] === data[k]['value']) || (typeof(response[key]['ToStaffId']) === "string" && parseInt((response[key]['ToStaffId']).trim(), 10) === (data[k]['value']) ) ){

                response[key]['ToStaffId'] = data[k]['label'];
              }
              if ( (typeof(response[key]['FromStaffId']) === "number" && response[key]['FromStaffId'] === data[k]['value']) || (typeof(response[key]['FromStaffId']) === "string" && parseInt((response[key]['FromStaffId']).trim(), 10) === (data[k]['value']) ) ){

                response[key]['FromStaffId'] = data[k]['label'];
              }
            }
            arr.push(response[key]);
        }

        if (this._isMounted){
          this.setState({transfers: arr});
          this.setState({check: true});
        }



    })
    .catch(err => console.error(err));

  })
  .catch(err => console.error(err));
  }

  sortDates (a, b, order) {
    // console.log('sortDate');
    // console.log(order)
    var dateA = new Date(a), dateB = new Date(b);
    if (order === 'asc'){
      return dateA - dateB;
    }
    return dateB - dateA;
  }

  render() {
    const {transfers} = this.state;
    // const {staffs} = this.state;
    const {check} = this.state;

    if(check === false){
      return (<div>
        {/* <Toast ref={(el) => this.toast = el} /> */}
        <div className='tab'><span>Pending Applications</span></div>
        <div className = "table-container">
        <div className="only-row">Loading...</div>
       </div>
       </div>);
    }

    else{
      if (transfers.length === 0){
        return (<div>
          <Toast ref={(el) => this.toast = el} />
          <div className='tab'><span>Pending Applications</span></div>
          <div className = "table-container">
          <div className="only-row">No Transfer Found</div>
         </div>
         </div>);
      }
      else{
        return (<div>
          <Toast ref={(el) => this.toast = el} />
          <div className='tab'><span>Pending Applications</span></div>
          <div className = "table-container">
          <div className="card">
            <TransferTable transfers={transfers} pending={true} type="admin" onClickButton={this.onClickButton}/>
          </div>
          </div>
          </div>
        )
      }
    }


    // const columns = [{
    //   dataField: 'FromStaffId',
    //   text:'FROM',
    //   // filter: textFilter()
    //   formatter: (data) => {
    //     return this.noFormatColumn(data['FromStaffId'], 'FROM');
    //   }
    // },
    // {
    //   dataField: 'ToStaffId',
    //   text:'TO',
    //   // filter: textFilter()
    //   formatter: (data) => {
    //     return this.noFormatColumn(data['ToStaffId'], 'TO');
    //   }
    // }, {
    //   dataField: 'SubmitDate',
    //   text:'SUBMIT DATE',
    //   // sort: true,
    //   // sortFunc: this.sortDates,
    //   // style: { 'width': '100px'},
    //   formatter: (data) => {
    //     // return moment(submit).format('DD-MMM-YYYY');
    //     return this.formatDate(data['SubmitDate'], 'SUBMIT DATE');
    //   },
    //   // headerFormatter: (column, index, { sortElement, filterElement }) => {
    //   //   return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

    //   // }
    // },{
    //   dataField: 'Amount',
    //   text:'AMOUNT (SGD)',
    //   // sort: true,
    //   // sortFunc: (a, b, order, dataField, rowA, rowB) => {
    //   //   if (order === 'asc') {
    //   //     return b - a;
    //   //   }
    //   //   return a - b; // desc
    //   // },
    //   formatter: (data) => {
    //     // return '$ '+amount;
    //     return this.formatCurrency(data['Amount'], 'AMOUNT (SGD)');
    //   },
    //   // headerFormatter: (column, index, { sortElement, filterElement }) => {
    //   //   return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
    //   // }
    // }, {
    //   dataField: 'StartDate',
    //   text:'FIRST TRANSFER DATE',
    //   // sort: true,
    //   // sortFunc: this.sortDates,
    //   formatter: (data) => {
    //     // return moment(from).format('DD-MMM-YYYY');
    //     return this.formatDate(data['StartDate'], 'FIRST TRANSFER DATE');
    //   },
    //   // headerFormatter: (column, index, { sortElement, filterElement }) => {
    //   //   return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
    //   // }
    // }, {
    //   dataField: 'Times',
    //   text:'MONTH(S)',
    //   // sort: true,
    //   // sortFunc: (a, b, order, dataField, rowA, rowB) => {
    //   //   if (order === 'asc') {
    //   //     return b - a;
    //   //   }
    //   //   return a - b; // desc
    //   // },
    //   // headerFormatter: (column, index, { sortElement, filterElement }) => {
    //   //   return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
    //   // }
    //   formatter: (data) => {
    //     return this.noFormatColumn(data['Times'], 'MONTH(S)')
    //   }
    // }, {
    //   dataField: 'EndDate',
    //   text:'LAST TRANSFER DATE',
    //   // sortFunc: this.sortDates,
    //   // sort: true,
    //   formatter: (data) => {
    //     // return moment(end).format('DD-MMM-YYYY');
    //     return this.formatDate(data['EndDate'], 'LAST TRANSFER DATE');
    //   },
    //   // headerFormatter: (column, index, { sortElement, filterElement }) => {
    //   //   return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

    //   // }
    // }, {
    //   dataField: 'Status',
    //   text:'STATUS',
    //   // sort: true,
    //   formatter: (data) => {
    //     // return status.charAt(0).toUpperCase() + status.substr(1);
    //     return this.formatStatus(data['Status'], 'STATUS');
    //   }
    // },{
    //   text:'ACTION',
    //   dataField:"button",
    //   style: { 'width': '170px'},
    //   formatter: (cell, row) => {
    //     return this.buttonFormatter(cell, row, 'ACTION');
    //   }
    // }];

    // const defaultSorted = [{
    //   dataField: 'SubmitDate',
    //   order: 'desc'
    // }];

    // const options = {
    //   hidePageListOnlyOnePage: true,
    //   sizePerPage: 10,
    //   hideSizePerPage: true
    // }


    // if(check === false){
    //   return (<div>
    //     <div className='tab'><span>Pending Applications</span></div>
    //     <div className = "table-container">
    //     <div className="only-row">Loading...</div>
    //    </div>
    //    </div>);
    // }

    // else{
    //   if (transfers.length === 0){
    //     return (<div>
    //       <div className='tab'><span>Pending Applications</span></div>
    //       <div className = "table-container">
    //       <div className="only-row">No Transfer Found</div>
    //      </div>
    //      </div>);
    //   }
    //   else{
    //     return (<div>
    //       <div className='tab'><span>Pending Applications</span></div>
    //       <div className = "table-container">
    //       <div className="card">
    //       <DataTable  
    //         className="p-datatable-striped"
    //         ref={(el) => this.dt = el}
    //         value={transfers}
    //         resizableColumns 
    //         columnResizeMode="fit"
    //         // header={header}
    //         paginator
    //         rows={10}
    //         sortField="SubmitDate"
    //         sortOrder={-1}
    //         removableSort
    //         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    //         rowsPerPageOptions={[10,30,50]}
    //         paginatorPosition="both"
    //       >
    //         {columns.map(column => {
    //           return (column.text=='FROM' || column.text=='TO' ? <Column 
    //           key={"Id"}
    //           field={column.dataField}
    //           header={column.text}
    //           sortable
    //           style={{fontSize: '15px'}}
    //           filter
    //           headerStyle={{
    //             width: '17%',
    //             fontSize: '14px'
    //           }}
    //           filterPlaceholder={"Enter " + column.text + "..."}
    //           filterMatchMode="contains"
    //           body={column.formatter}
    //         /> : (column.text=='ACTION') ? <Column 
    //         key={"Id"}
    //         field={column.dataField}
    //         header={column.text}
    //         sortable
    //         headerStyle={{
    //           width: '15%',
    //           fontSize: '14px'
    //         }}
    //         style={{fontSize: '15px'}}
    //         body={column.formatter}
    //         /> : <Column 
    //         key={"Id"}
    //         field={column.dataField}
    //         header={column.text}
    //         sortable
    //         style={{fontSize: '15px'}}
    //         headerStyle={{
    //           width: '11%',
    //           fontSize: '14px'
    //         }}
    //         body={column.formatter}
    //         />)
    //         })}
    //       </DataTable>
    //       {/* <BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } filter={ filterFactory() } pagination={  paginationFactory(options)  } /> */}
    //     </div>
    //     </div>
    //     </div>);
    //   }
    // }

  }
}

export default Transfer_All_Pending;
