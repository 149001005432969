import React, { Component } from "react";
// import Select from 'react-select';
import DropdownComponent from "../components/dropdown";
import { changeFloat, validInput, roundup, numberWithCommas_display as numberWithCommas, removeCommas } from "./service/excelService";
// import {changeFloat, roundup, numberWithCommas} from "./service/excelService";
import { fetch_retry } from "./service/getinfo";
// import { NotificationManager } from 'react-notifications';
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import withRouter from "../components/withRouter";
// import Logout from "./../logout";
import CheckButton from "../components/checkButton";
// import ProcessReserveTable from "../components/process_reserve_table";
import { Toast } from "primereact/toast";

var service = require('./service/getinfo.js');
var logging = require('./service/loggingComponent.js');
var config = require('../config.js');
const backend = config.backend;
const loggingHeader = '[frontend][process_reserve_per_employee]';
// const customStyles = service.customStyles;

class Process_Reserve extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.checkReserve = this.checkReserve.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickInside = this.handleClickInside.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
    this.showInfo = this.showInfo.bind(this);
    // this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      budgets: [],
      staffs: [],
      months: [],
      years: [],
      staff: '',
      month: '',
      year: '',
      errors: {},
      reserve: {
        'Id': '',
        'StaffId': '',
        'Month': '',
        'Year': '',
        'BalanceBF': '',
        'BalanceBF_rmk': '',
        'Donations': '',
        'Donations_rmk': '',
        'CashProject': '',
        'CashProject_rmk': '',
        'TransferIN': '',
        'TransferIN_rmk': '',
        'GovernCredit': '',
        'GovernCredit_rmk': '',
        'IncomeOthers': '',
        'IncomeOthers_rmk': '',
        'Budget': '',
        'Budget_rmk': '',
        'Insurance': '',
        'Insurance_rmk': '',
        'AdminCharges': '',
        'AdminCharges_rmk': '',
        'APLT': '',
        'APLT_rmk': '',
        'CDAC': '',
        'CDAC_rmk': '',
        'SINDA': '',
        'SINDA_rmk': '',
        'EmperCPF': '',
        'EmperCPF_rmk': '',
        'EmpeeCPF': '',
        'EmpeeCPF_rmk': '',
        'RelatedFunds': '',
        'RelatedFunds_rmk': '',
        'NetSalary': '',
        'NetSalary_rmk': '',
        'MinistryExp': '',
        'MinistryExp_rmk': '',
        'TransferOUT': '',
        'TransferOUT_rmk': '',
        'CreditCard': '',
        'CreditCard_rmk': '',
        'Cheque': '',
        'Cheque_rmk': '',
        'OutcomeOthers': '',
        'OutcomeOthers_rmk': '',
        'ReservedFundCF': '',
        'ReservedFundCF_rmk': ''
      },
      reserve_new: {
        'Id': '',
        'StaffId': '',
        'Month': '',
        'Year': '',
        'BalanceBF': '',
        'BalanceBF_rmk': '',
        'Donations': '',
        'Donations_rmk': '',
        'CashProject': '',
        'CashProject_rmk': '',
        'TransferIN': '',
        'TransferIN_rmk': '',
        'GovernCredit': '',
        'GovernCredit_rmk': '',
        'IncomeOthers': '',
        'IncomeOthers_rmk': '',
        'Budget': '',
        'Budget_rmk': '',
        'Insurance': '',
        'Insurance_rmk': '',
        'AdminCharges': '',
        'AdminCharges_rmk': '',
        'APLT': '',
        'APLT_rmk': '',
        'CDAC': '',
        'CDAC_rmk': '',
        'SINDA': '',
        'SINDA_rmk': '',
        'RelatedFunds': '',
        'RelatedFunds_rmk': '',
        'EmperCPF': '',
        'EmperCPF_rmk': '',
        'EmpeeCPF': '',
        'EmpeeCPF_rmk': '',
        'NetSalary': '',
        'NetSalary_rmk': '',
        'MinistryExp': '',
        'MinistryExp_rmk': '',
        'TransferOUT': '',
        'TransferOUT_rmk': '',
        'CreditCard': '',
        'CreditCard_rmk': '',
        'Cheque': '',
        'Cheque_rmk': '',
        'OutcomeOthers': '',
        'OutcomeOthers_rmk': '',
        'ReservedFundCF': '',
        'ReservedFundCF_rmk': ''
      },
      checked: 'false',
      getData: false,
      role: '',
      cashpro_input: false,
      govern_input: false,
      income_input: false,
      budget_input: false,
      insurance_input: false,
      ministryexp_input: false,
      outcome_input: false,
      redirectLogout: false
    }
  }



  componentDidMount() {
    this._isMounted = true;

    this.getAllStaff();
    this.renderMonth();
    this.renderYear();
    // this.checkReserve();
    this.renderReserveTable();
  }

  componentWillMount() {
    this._isMounted = false;
    // document.addEventListener('mousedown', this.handleClick, false);
  }


  componentWillUnmount() {
    this._isMounted = false;
    // document.removeEventListener('mousedown', this.handleClick, false);
  }

  showSuccess(message, summary) {
    this.toast.show({severity:'success', summary: summary, detail:message, life: 3000});
  }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Save Failed', detail:message, life: 3000});
  } 

  showInfo(message) {
    this.toast.show({severity:'info', summary: 'Info Message', detail:message, life: 7000});
}

  handleClick = (e) => {
    if (!this.node.contains(e.target)) {
      // console.log('click outside');
      this.setState({ cashpro_input: false });
      this.setState({ govern_input: false });
      this.setState({ income_input: false });
      this.setState({ budget_input: false });
      this.setState({ insurance_input: false });
      this.setState({ ministryexp_input: false });
      this.setState({ outcome_input: false });
      this.handleClickInside(e.target);
      return;
    }

  }


  handleClickInside = (x) => {
    console.log('click inside! toggle formatting', x, x.name, x.value);
    if (x.name === 'CashProject') {
      this.setState({ cashpro_input: true });
      // console.log('click inside! CashProject set True');
    }
    if (x.name === 'GovernCredit') {
      // console.log('click inside! GovernCredit');
      this.setState({ govern_input: true });
    }
    if (x.name === 'IncomeOthers') {
      // console.log('IncomeOthers');
      this.setState({ income_input: true });
    }
    if (x.name === 'Budget') {
      // console.log('Budget');
      this.setState({ budget_input: true });
    }
    if (x.name === 'Insurance') {
      // console.log('Insurance');
      this.setState({ insurance_input: true });
    }
    if (x.name === 'MinistryExp') {
      // console.log('MinistryExp');
      this.setState({ ministryexp_input: true });
    }
    if (x.name === 'OutcomeOthers') {
      // console.log('OutcomeOthers');
      this.setState({ outcome_input: true });
    }

  }


  getFundCF = (incomeTotal, outcomeTotal) => {
    return new Promise(function (resolve, reject) {
      resolve(Math.round((incomeTotal - outcomeTotal) * 100) / 100);
    });
  }

  getAllStaff = _ => {
    // Get all the staffs
    // fetch(backend + `api/staffsimple`)
    fetch(backend + `api/allreservestaff`, {   // 29 Apr add in former field staff
      method: 'GET',
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {

        if (res.redirect) {
          sessionStorage.setItem('int', 'logout');
          this.setState({ redirectLogout: true })
          window.location.reload(false);
        }

        if (this._isMounted) {
          this.setState({ staffs: res.data });
        }

      });
  }


  renderMonth = _ => {

    service.getMonthList()
      .then((months_drop) => {
        this.setState({ months: months_drop });
      })


  }

  renderYear = _ => {

    service.getYearList()
      .then((years_drop) => {
        this.setState({ years: years_drop });
      })

  }


  handleMonthChange = (m) => {
    // logging.logToGoogle(loggingHeader + ' click month dropdown list');
    var month = parseInt(m.value, 10)
    this.setState({ month: month.toString() });
  }

  handleYearChange = (y) => {
    // logging.logToGoogle(loggingHeader + ' click year dropdown list');
    this.setState({ year: y.value });
  }

  handleStaffChange = (toStaff) => {
    // logging.logToGoogle(loggingHeader + ' Click staff dropdown list');
    this.setState({ staff: toStaff.value });
  }

  inputValid = () => {
    let errors = {};
    let formIsValid = true;

    const { year } = this.state;
    const { month } = this.state;
    const { staff } = this.state;

    // console.log('year', year);
    // console.log('month', month);
    // console.log('staff', staff);


    //Check To Staff Id
    if (!(isFinite(String(year)) && year !== '')) {
      console.log(isFinite(String(year)));
      errors['year'] = 'Please choose a year!';
      formIsValid = false;
    }

    if (!(isFinite(String(month)) && month !== '' && month !== null)) {
      errors['month'] = 'Please choose a month!';
      formIsValid = false;
    }


    if (!(isFinite(String(staff)) && staff !== '')) {
      errors['staff'] = 'Please choose a staff!';
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  // Called by 'CHECK' button to get data and set the state
  checkReserve = _ => {
    const { year } = this.state;
    const { month } = this.state;
    const { staff } = this.state;
    this.setState({ reserve: {} });
    this.setState({ reserve_new: {} });
    // logging.logToGoogle(loggingHeader + ' clicked CHECK button');

    if (!this.inputValid()) {
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!')
      // Notification.createNotification('error', 'Please check your input!')
    }
    else {
      this.setState({ checked: 'process' });

      fetch_retry(backend + `api/scheduler/displayReserve/` + staff + '/' + month + '/' + year, {
        method: 'GET',
        credentials: "include"
      }, 2)
        // fetch(backend + `api/scheduler/displayReserve/` + staff + '/'+ month + '/' + year)
        // .then((res) => {console.log(res)})
        .then((res) => res.json())
        .then((res) => {
          console.log(res)
          if (this._isMounted) {
            this.setState({ checked: 'true' });
          }
          // console.log('return from fetch', res);
          if (res.length > 1) {
            console.log('error: multiple records returned');
          }
          else if (res.hasOwnProperty('error')) {
            console.log('error found', res['error'])
          }
          else if (res.hasOwnProperty('data')) {
            // console.log('record found', res.data);

            const reserve_fields = ['BalanceBF', 'GovernCredit', 'IncomeOthers', 'OutcomeOthers', 'ReservedFundCF', 'CashProject', 'Budget', 'Insurance', 'MinistryExp', 'APLT', 'AdminCharges', 'CDAC', 'CreditCard', 'Donations', 'EmpeeCPF', 'EmperCPF', 'NetSalary', 'RelatedFunds', 'SINDA', 'TransferIN', 'TransferOUT', 'BalanceBF_rmk', 'GovernCredit_rmk', 'IncomeOthers_rmk', 'OutcomeOthers_rmk', 'ReservedFundCF_rmk', 'TransferIN_rmk', 'TransferOUT_rmk', 'Donations_rmk', 'CashProject_rmk', 'CreditCard_rmk', 'CDAC_rmk', 'SINDA_rmk', 'EmperCPF_rmk', 'EmpeeCPF_rmk', 'NetSalary_rmk', 'Budget_rmk', 'Insurance_rmk', 'MinistryExp_rmk', 'AdminCharges_rmk', 'APLT_rmk', 'RelatedFunds_rmk'];


            var reserve = res.data;
            var reserve_new = res.data;


            var i;

            for (i = 0; i < reserve_fields.length; i++) {
              var k = reserve_fields[i];

              if (k.match(/_rmk/gi)) {
                if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === 0 || reserve[k] === undefined) {
                  // console.log(k)
                  reserve_new[k] = "";
                  // reserve[k] = "";
                }
              }
              else {
                if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === "" || reserve[k] === undefined) {
                  reserve_new[k] = 0;
                }
                else {
                  reserve_new[k] = parseFloat(reserve[k]);
                }
              }
            }

            var role = res.data.role;
            // console.log('role',role)
            this.setState({ role: role });


            if (i === reserve_fields.length) {
              this.setState({ reserve: reserve_new });
              this.setState({ reserve_new: reserve_new });

            }

          }
          else {
            console.log('error', res);
          }

        })
        .catch((error) => {
          console.log(error)
        });
    }

  }



  handleSubmit = _ => {
    const { reserve } = this.state;
    const { staff } = this.state;
    const { month } = this.state;
    const { year } = this.state;
    const role = reserve.role
    logging.logErrorToGoogle(loggingHeader + ' clicked Saved button');

    // console.log('front end',reserve);

    fetch_retry(backend + `api/reserve/updateOneReserve/` + staff + `/` + month + `/` + year, {
      method: 'PUT',
      credentials: "include",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reserve
      })
    }, 3)
      .then(function (response) {
        // console.log(response.status);
        return response.json()
      })
      .then(body => {
        var msg1 = 'Reserve for this month is submitted successful!'
        // NotificationManager.success(msg1);
        this.showSuccess(msg1, 'Submit Success');
        // Notification.createNotification('success', msg1)

        var msg2 = 'It may take a few more seconds for the following months to be updated.'
        // NotificationManager.info(msg2, '', 7000);
        this.showInfo(msg2)
        // Notification.createNotification('info', msg2,'', 7000)

        if (body[0].sql.length === 0) {

          // After this reserve updated succesfully, auto update following months
          var next_m;
          var next_y;
          let cur_m = parseInt(month);
          let cur_year = parseInt(year);

          if (cur_m === 12) {
            next_m = 1;
            next_y = cur_year + 1;
          }
          else {
            next_m = cur_m + 1;
            next_y = cur_year;
          }

          // If it is wife, this month need to send to scheduler to process to update husband
          if (role === 2) {
            next_m = cur_m
            next_y = cur_year
          }

          // console.log(next_m, next_y, staff);

          fetch(backend + `api/scheduler/autoUpdate/` + staff + `/` + next_m + `/` + next_y, {
            method: 'PUT',
            credentials: "include",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          })
            .then(res => {

              // NotificationManager.success('Auto update finished!');
              this.showSuccess('Auto update finished!', 'Update Success');
              // Notification.createNotification('success', 'Auto update finished!')

            })
            // .catch(error => {
            //   this.showError('Auto update failed!');
            // })
          // window.location.reload();
        }

      });

  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }


  // renderReserve(reserve_o, role){
  renderReserve() {

    // console.log('reserve_o', reserve_o)

    var reserve = this.state.reserve;
    console.log(reserve)

    var reserve_new = this.state.reserve_new;

    var role = reserve.role;

    var cashpro_input = this.state.cashpro_input;
    var govern_input = this.state.govern_input;
    var income_input = this.state.income_input;
    var budget_input = this.state.budget_input;
    var insurance_input = this.state.insurance_input;
    var ministryexp_input = this.state.ministryexp_input;
    var outcome_input = this.state.outcome_input;

    var incomeTotal;
    var outcomeTotal = roundup(changeFloat(reserve.Insurance) + changeFloat(reserve_new.AdminCharges) + changeFloat(reserve_new.APLT) + changeFloat(reserve_new.CDAC) + changeFloat(reserve_new.SINDA) + changeFloat(reserve_new.RelatedFunds) + changeFloat(reserve_new.EmpeeCPF) + changeFloat(reserve_new.EmperCPF) + changeFloat(reserve_new.NetSalary) + changeFloat(reserve.MinistryExp) + changeFloat(reserve_new.TransferOUT) + changeFloat(reserve_new.CreditCard) + changeFloat(reserve_new.Cheque) + changeFloat(reserve.OutcomeOthers));
    var sOrd_addition;
    var sOrd_deduction = roundup(changeFloat(reserve.Budget) - changeFloat(outcomeTotal));

    var reserveCF;

    // Else is is a single or husband
    // else{
    // console.log(role);

    // console.log(reserve.Donations);
    incomeTotal = roundup(changeFloat(reserve_new.BalanceBF) + changeFloat(reserve_new.Donations) + changeFloat(reserve_new.CashProject) + changeFloat(reserve_new.TransferIN) + changeFloat(reserve.GovernCredit) + changeFloat(reserve.IncomeOthers));
    sOrd_addition = roundup(changeFloat(reserve_new.Donations) - changeFloat(reserve.Budget));

    reserveCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));

    reserve_new.ReservedFundCF = reserveCF;
    reserve.ReservedFundCF = reserveCF;
    // }

    var total_Ratio;
    if (reserve_new.Budget === 0) {
      // total_Ratio = 'Budget is not defined';
      total_Ratio = '0%';
    }
    else {
      total_Ratio = (Math.round((reserve_new.Donations / reserve.Budget) * 10000) / 100) + '%';
    }


    if (reserve !== null && reserve_new !== null && reserve !== undefined && reserve_new !== undefined) {
      return (

        <tbody>
          <tr>
            <td><label>Addition</label></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="auto">
            <td>Balance B/F</td>
            <td key='BalanceBF'>{numberWithCommas(reserve_new.BalanceBF)}</td>
            {/*<textarea  rows="1" wrap="hard" value={reserve.BalanceBF_rmk} onChange={e=> this.setState({ reserve:{...this.state.reserve, BalanceBF_rmk: e.target.BalanceBF_rmk}})}> </textarea>*/}

            <td key='BalanceBF_rmk'>{reserve_new.BalanceBF_rmk}
            </td>
          </tr>
          <tr>
            <td>Donations</td>
            {(role === 2 || role === 3) ?
              <td key='Donations'>{numberWithCommas(reserve.Donations)} &nbsp;&nbsp;&nbsp; (Couple's Total Donations: {numberWithCommas(reserve.Couple)})</td>
              :
              <td key='Donations'>{numberWithCommas(reserve.Donations)}</td>
            }
            {/*}<td><textarea rows="1" wrap="hard" value={reserve_new.Donations_rmk}  onChange={e=> {
              this.setState({ reserve:{...this.state.reserve, Donations_rmk: e.target.value}});
              this.setState({ reserve_new:{...this.state.reserve, Donations_rmk: e.target.value}})
            }}> </textarea></td>*/}
            <td><textarea rows="1" wrap="hard" value={this.state.reserve_new.Donations_rmk} onChange={e => {
              this.setState({ reserve: { ...this.state.reserve, Donations_rmk: e.target.value } });
              this.setState({ reserve_new: { ...this.state.reserve_new, Donations_rmk: e.target.value } })
            }}></textarea></td>
          </tr>
          <tr>
            <td>Cash Project</td>

            {
              (cashpro_input) ?
                <td key='CashProject'>
                  <input name="CashProject" type='number' value={removeCommas(reserve.CashProject)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, CashProject: validInput(e.target.value) } });
                    this.setState({ reserve_new: { ...this.state.reserve_new, CashProject: validInput(e.target.value) } });
                    this.handleClickInside(e.target);
                  }} />
                </td>
                :
                <td key='CashProject'>
                  <input name="CashProject" value={numberWithCommas(reserve.CashProject)} onClick={e => { this.handleClickInside(e.target) }} onChange={e => { this.handleClickInside(e.target) }} />
                </td>
            }

            {/*}<td ref={node => this.node = node} key='CashProject'><input name="CashProject" value = {reserve.CashProject} onChange={e=>{
              this.setState({ reserve:{...this.state.reserve, CashProject: e.target.value}});this.setState({ reserve_new:{...this.state.reserve_new, CashProject: e.target.value}});this.handleClickInside(e.target);
            }}/></td>*/}
            <td><textarea rows="1" wrap="hard" value={reserve_new.CashProject_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, CashProject_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, CashProject_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Transfer (IN)</td>
            <td key='TransferIN'> {numberWithCommas(reserve.TransferIN)}
              <ul className="dropdown" dangerouslySetInnerHTML={{ __html: reserve_new.TransferIN_details }}></ul>
            </td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.TransferIN_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, TransferIN_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, TransferIN_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Government Credit</td>

            {
              (govern_input) ?
                <td key='GovernCredit'>
                  <input name="GovernCredit" type='number' value={removeCommas(reserve.GovernCredit)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, GovernCredit: validInput(e.target.value) } });
                    this.handleClickInside(e.target);
                  }} />
                </td>
                :
                <td key='GovernCredit'>
                  <input name="GovernCredit" value={numberWithCommas(reserve.GovernCredit)} onClick={e => { this.handleClickInside(e.target) }} onChange={e => { this.handleClickInside(e.target) }} />
                </td>
            }

            <td><textarea rows="1" wrap="hard" value={reserve_new.GovernCredit_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, GovernCredit_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, GovernCredit_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Others</td>
            {

              (income_input) ?
                <td key='IncomeOthers'>
                  <input name="IncomeOthers" type='number' value={removeCommas(reserve.IncomeOthers)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, IncomeOthers: validInput(e.target.value) } });
                    this.handleClickInside(e.target);
                  }} />
                </td>
                :
                <td key='IncomeOthers'>
                  <input name="IncomeOthers" value={numberWithCommas(reserve.IncomeOthers)} onClick={e => { this.handleClickInside(e.target) }} onChange={e => { this.handleClickInside(e.target) }} />
                </td>


            }
            <td><textarea rows="1" wrap="hard" value={reserve_new.IncomeOthers_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, IncomeOthers_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, IncomeOthers_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr className="auto">
            <td>Total</td>
            <td>{numberWithCommas(incomeTotal)}</td>
            <td></td>
          </tr>
          <tr className="auto">
            <td>Budget</td>
            {

              (budget_input) ?
                <td key='Budget'>
                  <input name="Budget" type='number' value={removeCommas(reserve.Budget)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, Budget: validInput(e.target.value) } }); this.handleClickInside(e.target)
                  }
                  } />
                </td>
                :
                <td key='Budget'>
                  <input name="Budget" value={numberWithCommas(reserve.Budget)} onChange={e => { this.handleClickInside(e.target) }} onClick={e => { this.handleClickInside(e.target) }} />
                </td>

            }
            <td>{reserve_new.Budget_rmk}</td>
          </tr>
          <tr className="auto">
            <td>Surplus/Deficit</td>
            <td>{numberWithCommas(sOrd_addition)}</td>
            <td></td>
          </tr>

          <tr><td></td><td></td><td></td></tr>

          <tr>
            <td><label>Deduction</label></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Insurance</td>
            {

              (insurance_input) ?
                <td key='Insurance'>
                  <input name="Insurance" type='number' value={removeCommas(reserve.Insurance)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, Insurance: validInput(e.target.value) } }); this.handleClickInside(e.target);
                  }} />
                </td>
                :
                <td key='Insurance'><input name="Insurance" value={numberWithCommas(reserve.Insurance)} onClick={e => { this.handleClickInside(e.target) }} onChange={e => { this.handleClickInside(e.target) }} /></td>

            }
            <td><textarea rows="1" wrap="hard" value={reserve_new.Insurance_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, Insurance_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, Insurance_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Singapore Admin Charges</td>
            <td key='AdminCharges'> {(reserve.AdminCharges)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.AdminCharges_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, AdminCharges_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, AdminCharges_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>APLT</td>
            <td key='APLT'> {numberWithCommas(reserve.APLT)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.APLT_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, APLT_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, APLT_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>CDAC Funds</td>
            <td key='CDAC'> {numberWithCommas(reserve.CDAC)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.CDAC_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, CDAC_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, CDAC_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>SINDA Funds</td>
            <td key='SINDA'> {numberWithCommas(reserve.SINDA)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.SINDA_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, SINDA_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, SINDA_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Other Funds</td>
            <td key='RelatedFunds'> {numberWithCommas(reserve.RelatedFunds)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.RelatedFunds_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, RelatedFunds_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, RelatedFunds_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          {/*this.CDAC_SINDA(reserve)*/}
          {/*<tr>
            <td>CDAC Funds</td>
            <td>{CDAC}</td>
            <td>{reserve.CDAC_rmk}</td>
          </tr>*/}
          <tr>
            <td>Employer CPF</td>
            <td key='EmperCPF'> {numberWithCommas(reserve.EmperCPF)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.EmperCPF_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, EmperCPF_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, EmperCPF_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Employee CPF</td>
            <td key='EmpeeCPF'> {numberWithCommas(reserve.EmpeeCPF)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.EmpeeCPF_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, EmpeeCPF_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, EmpeeCPF_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Net Salary</td>
            <td key='NetSalary'> {numberWithCommas(reserve.NetSalary)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.NetSalary_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, NetSalary_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, NetSalary_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Ministry Expenses</td>
            {
              (ministryexp_input) ?
                <td key='MinistryExp'>
                  <input name="MinistryExp" type='number' value={removeCommas(reserve.MinistryExp)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, MinistryExp: validInput(e.target.value) } });
                    this.handleClickInside(e.target);
                  }} />
                </td>
                :
                <td key='MinistryExp'>
                  <input name="MinistryExp" value={numberWithCommas(reserve.MinistryExp)} onClick={e => { this.handleClickInside(e.target) }} onChange={e => { this.handleClickInside(e.target) }} />
                </td>
            }
            <td><textarea rows="1" wrap="hard" value={reserve_new.MinistryExp_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, MinistryExp_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, MinistryExp_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Transfer (Out)</td>
            <td key='TransferOUT'> {numberWithCommas(reserve.TransferOUT)}
              <ul className="dropdown" dangerouslySetInnerHTML={{ __html: reserve_new.TransferOUT_details }}></ul>
            </td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.TransferOUT_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, TransferOUT_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, TransferOUT_rmk: e.target.value } }) }}></textarea></td>

          </tr>
          <tr>
            <td>Credit Card Charges</td>
            <td key='CreditCard'> {numberWithCommas(reserve.CreditCard)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.CreditCard_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, CreditCard_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, CreditCard_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Cheque Charges</td>
            <td key='Cheque'> {numberWithCommas(reserve.Cheque)}</td>
            <td><textarea rows="1" wrap="hard" value={reserve_new.Cheque_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, Cheque_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, Cheque_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr>
            <td>Others</td>
            {

              (outcome_input) ?
                <td key='OutcomeOthers'>
                  <input name="OutcomeOthers" type='number' value={removeCommas(reserve.OutcomeOthers)} onChange={e => {
                    this.setState({ reserve: { ...this.state.reserve, OutcomeOthers: validInput(e.target.value) } });
                    this.handleClickInside(e.target);
                  }} />
                </td>
                :
                <td key='OutcomeOthers'>
                  <input name="OutcomeOthers" value={numberWithCommas(reserve.OutcomeOthers)} onClick={e => { this.handleClickInside(e.target) }} onChange={e => { this.handleClickInside(e.target) }} />
                </td>


            }
            <td><textarea rows="1" wrap="hard" value={reserve_new.OutcomeOthers_rmk} onChange={e => { this.setState({ reserve: { ...this.state.reserve, OutcomeOthers_rmk: e.target.value } }); this.setState({ reserve_new: { ...this.state.reserve, OutcomeOthers_rmk: e.target.value } }) }}></textarea></td>
          </tr>
          <tr className="auto">
            <td>Total</td>
            <td>{numberWithCommas(outcomeTotal)}</td>
            <td></td>
          </tr>

          <tr className="auto">
            <td>Budget</td>
            <td>{numberWithCommas(reserve.Budget)}</td>
            <td></td>
          </tr>

          <tr className="auto">
            <td>Under/Over Spend</td>
            <td>{numberWithCommas(sOrd_deduction)}</td>
            <td></td>
          </tr>

          <tr>
            <td></td><td></td><td></td>
          </tr>

          <tr className="auto">
            <td>Reserve Fund C/F</td>
            {(role === 3) ?
              <td>{numberWithCommas(reserve_new.ReservedFundCF)} &nbsp;&nbsp;&nbsp; (Wife's Reserve: {numberWithCommas(reserve.WifeBalance)}, Couple's Total Reserve: {numberWithCommas(reserve_new.ReservedFundCF + reserve.WifeBalance)})</td>
              :
              <td>{numberWithCommas(reserve_new.ReservedFundCF)}</td>
            }
            {/*<td>{numberWithCommas(reserve_new.ReservedFundCF)}</td>*/}
            <td></td>
          </tr>

          <tr className="auto">
            <td>Total Collection vs Budget</td>
            <td>{total_Ratio}</td>
            <td></td>
          </tr>
        </tbody>
      )
    }

  }


  renderReserveTable() {
    const { reserve } = this.state;
    let checked = this.state.checked;
    // let role = this.state.role;
    console.log(reserve);

    if (checked === 'true') {
      if (reserve === undefined || this.isEmpty(reserve)) {
        // console.log('reserve undefined');
        return (<div className="only-row">No Reserve Found</div>);
      } else {
        // console.log('reserve exists');
        return (<div>
          {/* <ProcessReserveTable 
          handleClick={this.handleClick}
          handleSubmit={this.handleSubmit}
          renderMethod={this.renderReserve}
          data={{}}
          columns={['Category', 'Amount', 'Remark']}/> */}
          <div className="container">
            <div className="table-container reserve-table">
              <form>
                <table className="table  ">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Amount</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  {this.renderReserve()}
                </table>
              </form>

            </div>
            <div className="save-button">
              <button type="button" className="btn btn-primary submit process-reserve" onClick={this.handleSubmit}>SAVE</button>
            </div>
          </div>

        </div>);
      }
    }
    else if (checked === 'process') {
      // return (<h2>Loading...</h2>)
      return (<div className="only-row">Loading</div>);

    }
    else {
      return (<div></div>)
    }

  }



  render() {
    const { redirectLogout } = this.state;
    
    if (redirectLogout) {
      return (
        <BrowserRouter>
          <Switch>
            {/* <Route exact path="/logout" component={Logout} />
            <Redirect to="/logout" /> */}
            <Route path={this.props.location.pathname} render={() => <Redirect to="/logout" />} />
          </Switch>
        </BrowserRouter>
      )
    }

    const { staffs } = this.state;
    const { months } = this.state;
    const { years } = this.state;
    // const {reserve} = this.state;
    // const {budgets} = this.state;
    let reserve_table = this.renderReserveTable();

    let staffList = staffs.filter(staff => staff.value % 1000 != 222)

    staffs.sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });

    return (<div onMouseDown={this.handleClick}>
      <Toast ref={(el) => this.toast = el} />
      <div>
        <h3 className='title' ref={node => this.node = node}> Please Choose Month, Year and Staff </h3>

        {/*}<form className="form-horizontal">*/}
        <fieldset>
          <div className="buttons col-sm-3">
            <div className="btn-group">
              {/* <Select styles={customStyles} className='react-select-container' options={months} isSearchable="false" placeholder="month" onChange={this.handleMonthChange} /> */}
              <DropdownComponent
              options={months}
              placeholder="month"
              handleChange={this.handleMonthChange}
              value={this.state.month} />
              <span className="error">{this.state.errors["month"]}</span>
            </div>
          </div>

          <div className="buttons col-sm-3">
            <div className="btn-group ">
              {/* <Select styles={customStyles} className='react-select-container' options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange} /> */}
              <DropdownComponent
              options={years}
              placeholder="year"
              handleChange={this.handleYearChange}
              value={this.state.year} />
              <span className="error">{this.state.errors["year"]}</span>
            </div>
          </div>

          <div className="buttons col-sm-3">
            <div className="btn-group">
              {/* <Select styles={customStyles} className='react-select-container' options={staffs} isSearchable="false" placeholder="staff" onChange={this.handleStaffChange} /> */}
              <DropdownComponent
              options={staffList}
              placeholder="staff"
              handleChange={this.handleStaffChange}
              value={this.state.staff} />
              <span className="error">{this.state.errors["staff"]}</span>

            </div>
          </div>

          <div className="col-sm-3 check-button">
            {/* <button type="button" className="btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
            <CheckButton 
              checkReserve={this.checkReserve}/>
          </div>
        </fieldset>
        {/*}</form>*/}

      </div>
      <div className="reserve-table" id="reserve-table">
        {reserve_table}
      </div>

    </div>);
  }
}

export default withRouter(Process_Reserve);
