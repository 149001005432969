import React, { Component } from "react";
// import Select from 'react-select';
// import { InputText } from 'primereact/inputtext.js'
// import DatePicker from 'react-mobile-datepicker';
// import moment from 'moment';
import TransferApplyTable from "../../components/transferApplyTable.js";
var logging = require('../../admin/service/loggingComponent.js');
const loggingHeader = '[frontend][mobile][staff][transfer]';

var config = require('../../config.js');
const backend = config.backend;
// var service = require('../admin/service/getinfo.js');

function convertDate(date, formate) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return formate
         .replace(/Y+/, year)
         .replace(/M+/, month)
         .replace(/D+/, day)
}


class StaffMobileTransfer extends Component {

  constructor(props){
    super(props);
    // this.transfer = props;

    this.state = {
      staffs:[],
      transfer:{
        fromStaffId: this.props.id,
        toStaffId:'',
        amount:'',
        startDate:new Date(),
        times:'1',
        comments:''
      },
      errors:{},
      loading: 'true',
      toStaff: null,
      isButtonDisabled: false,
		  // isOpen: false,
      // noEnd:null
    }


    // this.handleDateChange = this.handleDateChange.bind(this);
    this.addTransfer = this.addTransfer.bind(this);
    this.resetTransfer = this.resetTransfer.bind(this);
    this.radioChange = this.radioChange.bind(this);
    this.timesChange = this.timesChange.bind(this);

  }



  // handleDateChange(date) {
  //
  //   const {transfer} = this.state;
  //   this.setState({
  //     startDate: date,
  //     transfer:{...transfer, startDate: date}
  //   });
  //
  // }

  checkedNoEnd = () =>{
    this.setState({ noEnd: true });
  }


  handleClick = () => {
    this.setState({ isOpen: true });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
  }

  handleSelect = (time) => {
    // this.setState({ time, isOpen: false });
    const {transfer} = this.state;
    this.setState({
      transfer:{...transfer, startDate: time},
      isOpen: false
    });

  }

  handleStaffChange = (toStaff) => {
    const {transfer} = this.state;
    // console.log(toStaff)
    if (toStaff !== null){
      this.setState({ transfer:{...transfer, toStaffId: toStaff.value}});
    }
    else{
      this.setState({ transfer:{...transfer, toStaffId: ''}})
    }
  }


  inputValid = () => {
    let {transfer} = this.state;
    let errors = {};
    let formIsValid = true;

    // var promise = new Promise((resolve, reject) => {
    //Check To Staff Id
    if (!(isFinite(String(transfer.toStaffId)) && transfer.toStaffId !== '' && String(transfer.toStaffId) !== transfer.fromStaffId )){
      errors['toStaff'] = 'Please choose correct staff!';
      formIsValid = false;
    }

    if (!(isFinite(String(transfer.amount)) && transfer.amount !== '' && transfer.amount !== null && Math.sign(transfer.amount) !== -1 )){
      errors['amount'] = 'Please enter correct amount!';
      formIsValid = false;
    }

    // (transfer times is a finite number && times is set && times is a whole number && it is not a negtive)
    // or no End is checked
    if (!((isFinite(String(transfer.times)) && transfer.times !== '' && transfer.times % 1 === 0 && Math.sign(transfer.times) !== -1) || (this.state.noEnd === 'true'))){
      errors['times'] = 'Please enter a whole number!';
      formIsValid = false;
    }


    this.setState({errors: errors});
    return formIsValid;
    // return promise;
  }

  componentWillMount() {
    this.getAllStaffs();
  }

  addTransfer = _ => {
    // logging.logToGoogle(loggingHeader + ' clicked submit for new transfers');
    const {transfer} = this.state;
    // const {noEnd} = this.state;

    // console.log('submitting', transfer);

    // if (noEnd){
    //   transfer.noEnd = 1;
    // }

    // this.inputValid();
    if (!this.inputValid()){
      alert('please check your input!')
    }
    else{
      this.setState({
        isButtonDisabled: true
      });

      fetch(backend + `api/transfer/new`,{
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
           transfer
        })
      })
      .then((response) => {
        this.resetTransfer();
        return response.json()
      })
      .then((body)=>{

        if (body[0].Id !== undefined){

          alert('Your application is submitted successfully');

        }

      })
      .catch((err) => {
        alert('Your application does not submitted successfully.\nPlease try again.');
        logging.logErrorToGoogle(loggingHeader + ' error: ' + err);
        console.log(err)
      });
    }

  }

  resetTransfer = _ => {

    var {transfer} = this.state;
    var toStaff = transfer.toStaffId

    this.setState({transfer:{
      fromStaffId: this.props.id,
      toStaffId:toStaff,
      amount:'',
      // startDate:'',
      startDate:new Date(),
      times:'',
      comments:''
    }})


    this.setState({
      isButtonDisabled: false,
      noEnd: null
    });


  }


  getAllStaffs = _ => {

    var promise = new Promise((resolve, reject) => {
      fetch(backend + `api/staffsimple`, {
        method: 'GET',
        credentials: 'include'
      })
      .then(response => response.json())
      .then(({data}) => {
        // console.log(data);
          this.setState({
              staffs: data,
              loading: 'false'
          });
        // });
        resolve (data)
      })
      .catch(err => console.error(err));
    });

    return promise;

  }


  radioChange(e) {
    console.log('radioChange')
    const {transfer} = this.state;

    this.setState({
      noEnd: e.currentTarget.value
    });


    if (transfer.times !== ''){ // if transfer times is set, reset it
      console.log(transfer.times, 'need to be reset');

      this.setState({
        transfer:{...transfer, times: ''}
      })


    }


  }



  timesChange(e) {
    const {transfer} = this.state;
    const {noEnd} = this.state;
    console.log('timesChange')

    this.setState({
      transfer:{...transfer, times: e.target.value}
    })

    if (noEnd){//If noEnd is alr checked, uncheck it

      console.log(noEnd, 'need to be unchecked');
      e.target.value = 'false'
      this.radioChange(e);
    }

  }

  textChange = (e) => {
    const { transfer } = this.state;
    this.setState({ transfer: { ...transfer, amount: e.target.value } })
  }

  notesChange = (e) => {
    const { transfer } = this.state;
    this.setState({ transfer: { ...transfer, comments: e.target.value } })
  }



  render() {
    const { transfer, staffs, loading, errors, isButtonDisabled } = this.state;

    staffs.sort((a,b)=> {
      return a.label > b.label ? 1 : -1;
    });

    if (this.state.loading === 'true'){

      return(
        <div className = 'transfer-apply-form'>
          <div className='mobile-tab'><span>Transfer Application</span></div>
          <div className = "well">
            <h4 className='title'>Loading...</h4>
          </div>
        </div>
      )

    }

    const dataConfig = {

      'date': {
          format: 'DD',
          caption: 'Day',
          step: 1,
      },
      'month': {
          format: 'MM',
          caption: 'Mon',
          step: 1,
      },
      'year': {
          format: 'YYYY',
          caption: 'Year',
          step: 1,
      }

    }


    const styles = {
      singleValue: (base, state) => ({
        ...base,
        color: state.selectProps.menuIsOpen ? '#C0C0C0' : base.color,
      })
    }



    return (
      <TransferApplyTable 
        transfer={transfer}
        staffs={staffs}
        loading={loading}
        errors={errors}
        handleStaffChange={this.handleStaffChange}
        addTransfer={this.addTransfer}
        isButtonDisabled={isButtonDisabled}
        textChange={this.textChange}
        notesChange={this.notesChange}
        />

      // <div className = 'mobile-transfer-apply-form'>
      //   {/*<h2>Transfer_Apply {this.props.id}</h2>*/}
      //   <div className='app_title'> <div className='mobile-tab'><span>Transfer Application</span></div> </div>
      //   <div className = "well">
      //   <form className="form-horizontal">
      //     <fieldset>
      //       <div className = "form-group">
      //           <label className = "col-lg-3 control-lebel"> TRANSFER TO</label>
      //           <div className="col-lg-9">
      //           <Select className='react-select-container' options={staffs} styles={styles} isSearchable="true" isClearable="true" placeholder="TYPE TO SEARCH FOR STAFF" onChange={this.handleStaffChange}/>
      //           <span className="error">{this.state.errors["toStaff"]}</span>
      //           </div>
      //      </div>

      //       <div className = "form-group">
      //           <label className = "col-lg-3 control-lebel"> AMOUNT</label>
      //           <div className="col-lg-9">
      //           <input className="form-control" type="number" placeholder="AMOUNT (ONLY NUMBER ACCEPTED)" value={transfer.amount} onChange={e=> this.setState({ transfer:{...transfer, amount: e.target.value}})}/>
      //           <span className="error">{this.state.errors["amount"]}</span>
      //           </div>
      //       </div>

      //       <div className = "form-group">
      //         <label className = "col-lg-3 control-lebel"> Date of Transfer</label>
      //         <div className="col-lg-9">
      //         {/* <div className="date"> */}
      //         {/*// onClick={this.handleClick}>
      //         // <span
      // 				// 	className="select-btn"
      // 				// 	>
      // 				// 	  {convertDate(this.state.transfer.startDate, 'DD-MM-YYYY')}
      // 				// </span>*/}

      //           <InputText
      //           value={(this.state.startDate === '') ? moment().format('DD-MM-YYYY') : moment(this.state.startDate).format('DD-MM-YYYY')}
      //           disabled
      //           className="date"
      //           />

      //         {/*<DatePicker
      //           selected={(this.state.transfer.startDate === '') ? new Date() : Date(this.state.transfer.startDate)}
      //           dateFormat='DD-MM-YYYY'
      //           // value={this.state.transfer.startDate}
      //           // isOpen={this.state.isOpen}
      //           // onSelect={this.handleSelect}
      //           // onCancel={this.handleCancel}
      //           // confirmText = {'OK'}
      //           // cancelText = {'CANCEL'}
      //           // headerFormat = 'DD-MM-YYYY'
      //           // dateConfig = {dataConfig}
      //           disabled={true}
      //       />*/}

      //         {/* <span className="error">{this.state.errors["startDate"]}</span> */}

      //         {/* </div> */}

      //         </div>
      //       </div>

      //       <div className = "form-group">
      //           <label className = "col-lg-3 control-lebel two-line"> <span>NOTES</span><br/><span>( SEND TO ADMIN STAFF )</span></label>
      //           <div className="col-lg-9">
      //           {/*<input className="form-control notes" style={{height: '100px'}} placeholder="NOTES" value={transfer.comments} onChange={e=> this.setState({ transfer:{...transfer, comments: e.target.value}})}/>*/}
      //           <textarea className="form-control notes" rows="3" wrap="hard" placeholder="NOTES" value={transfer.comments} onChange={e=> this.setState({ transfer:{...transfer, comments: e.target.value}})}> </textarea>

      //           </div>
      //       </div>

      //       <label>Your application of transfer will be processed once submitted. No future dated transfers allowed.</label>

      //       <div className = "form-group">
      //          <div className="col-lg-12 col-lg-offset-9">
      //             {/*}<button type="submit" className="btn btn-primary" onClick={this.addNewTransfer.bind(this)}>Submit</button>*/}
      //             <button type="button" className="btn btn-primary submit" onClick={this.addTransfer} disabled={this.state.isButtonDisabled}>SUBMIT FOR APPROVAL</button>
      //          </div>
      //       </div>
      //     </fieldset>
      //   </form>
      //   </div>

      // </div>

    );
  }
}


export default StaffMobileTransfer;
